import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import { API } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import moment from 'moment';
import CButton from "../../components/Button";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CModal from "../../components/Modal";
import CSelect from "../../components/Select";
import TextInput from '../../components/TextInput';
import { CDatePicker } from '../../components/DatePicker';
import { CText } from "../../components/Text";
import { useSelector } from 'react-redux';
import getLocalizedLabel from '../../utils/localizationUtils';
import ProfilePicture from "../../components/ProfilePicture/index";
import { ReactComponent as BandageIcon } from '../../assets/icons/bandage.svg';
import { ReactComponent as MedicalKitIcon } from '../../assets/icons/medical-kit.svg';
import { ReactComponent as WeightIcon } from '../../assets/icons/weight.svg';
import { ReactComponent as FatMassIcon } from '../../assets/icons/fat-mass.svg';
import { ReactComponent as DotsVerticalIcon } from '../../assets/icons/dots-vertical.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/cross.svg';
import { getCurrentSeason } from "../../utils/season";
import { Chart } from 'react-chartjs-2';
import './TeamPlayer.css';

const COLOR_PRIMARY = '#45d69f';
const COLOR_PRIMARY_TRANSPARENT = 'rgba(69, 214, 159, 0.4)';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_PINK_TRANSPARENT = 'rgba(233, 33, 99, 0.4)'
const COLOR_BLUE = "#25a5e6";
const COLOR_BLUE_TRANSPARENT = 'rgba(37, 165, 230, 0.4)';
const COLOR_PURPLE = "#9d44fd";
const COLOR_PURPLE_TRANSPARENT = 'rgba(157, 68, 253, 0.4)';
const COLOR_ORANGE = "#f2a624";
const COLOR_DARK = "#242834";
const COLOR_ORANGE_TRANSPARENT = 'rgba(242, 166, 36, 0.4)'
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;
const MENU_WIDTH = 250;

const formatDate = (date, language) => {
  let formattedDate;
  switch (true) {
    case language === "fr":
      formattedDate = moment(date).format("DD-MM-YYYY");
      break;
    default:
      formattedDate = moment(date).format("MM-DD-YYYY");
      break;
  }
  return formattedDate;
};

const Injuries = ({userId, season, t}) => {
  const [injuries, setInjuries] = useState({});
  const [playerInjuries, setPlayerInjuries] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [startDate, setStartDate] = useState("");

  useEffect(() => {
    const initialDate = moment().format("YYYY-MM-DD");
    setStartDate(initialDate);

    const fetchData = async () => {
      listInjuries()
      await listUserInjuries()
      setIsLoaded(true)
    }
  
    fetchData()
  }, [])

  const calculateEndDate = (startDate, duration) => {
    const startDateMoment = moment(startDate);
    const endDateMoment = startDateMoment.add(duration, 'days');
    const endDate = endDateMoment.format('YYYY-MM-DD');
    return endDate;
  };

  const listInjuries = async () => {
    try {
      let injuries = await API.graphql({
        query: queries.listInjuries,
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      injuries = injuries?.data?.listInjuries;
  
      const formattedInjuries = injuries.items.map((item) => ({
        label: getLocalizedLabel(item, i18next.language),
        code: item.id
      }));

      formattedInjuries.sort((a, b) => a.label.localeCompare(b.label));
  
      setInjuries(formattedInjuries);
    } catch (err) {
      console.log(err);
    }
  };
  
  const listUserInjuries = async () => {
    try {
      const filter = {
        user_id: { eq: userId },
        season: { eq: season },
      };
      let injuries = await API.graphql({
        query: queries.listPlayerInjuries,
        variables: { filter: filter },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      injuries = injuries?.data?.listPlayerInjuries.items;
  
      injuries.sort((a, b) => new Date(b.start_date) - new Date(a.start_date))

      injuries.forEach((item) => {
        const endDate = calculateEndDate(item.start_date, item.duration);
        item.end_date = endDate;
      });
  
      const formattedInjuries = injuries.map((item) => ({
        ...item,
        start_date: formatDate(item.start_date, i18next.language),
        end_date: formatDate(item.end_date, i18next.language),
        label: getLocalizedLabel(item.injury, i18next.language)
      }));
      
      setPlayerInjuries(formattedInjuries);
    } catch (err) {
      console.log(err);
    }
  };
  
  //Menu
  const [anchorEl, setAnchorEl] = useState(null)
  const [addBtn, setAddBtn] = useState(false)
  const [deleteBtn, setDeleteBtn] = useState(false)
  const [playerInjuryData, setPlayerInjuryData] = useState()

  const open = Boolean(anchorEl);
  const moreBtnClick = (event, addBtn, deleteBtn, data) => {
    setPlayerInjuryData()
    setAnchorEl(event.currentTarget)
    setAddBtn(addBtn)
    setDeleteBtn(deleteBtn)
    setPlayerInjuryData(data)
  };

  const handleClose = () => {
    setAnchorEl(null)
  };

  const clickAdd = async() => {
    setModalOpen(true)
    handleClose()
  };

  const onClickDelete = async () => {
    handleClose();
    if (playerInjuryData) {
      const deleteInput = {
        id: playerInjuryData.id
      };
  
      await API.graphql({
        query: mutations.deletePlayerInjury,
        variables: { input: deleteInput },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
  
      const updatedInjuries = playerInjuries.filter((item) => item.id !== playerInjuryData.id);
      setPlayerInjuries(updatedInjuries);
    }
  };

  /********* Handle Injury *********/
  const [injury, setInjury] = useState();
  const selectInjury = (data) => setInjury(data);

  /********* Date *********/
  const inputStartDate = (data) => { setStartDate(data) };

  /*********  *********/
  const [duration, setDuration] = useState();
  const inputDuration = (data) => setDuration(data)

  const addInjury = async () => {  
    const playerInjuryInput = {
      season: season,
      start_date: startDate,
      duration: duration,
      injury_id: injury.code,
      injuryUsersId: injury.code,
      user_id: userId,
      userInjuriesId: userId
    };

    const createdPlayerInjury = {
      ...playerInjuryInput,
      id: 'temp-id',
      start_date: formatDate(startDate, i18next.language),
      duration: duration,
      end_date: formatDate(calculateEndDate(startDate, duration), i18next.language) ,
      label: injury.label
    };
  
    setPlayerInjuries((prevPlayerInjuries) => {
      const updatedInjuries = [createdPlayerInjury, ...prevPlayerInjuries];
      return updatedInjuries;
    });
  
    // Créer l'objet playerInjury sur le serveur
    const createPlayerInjuryResp = await API.graphql({
      query: mutations.createPlayerInjury,
      variables: { input: playerInjuryInput },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
  
    // Mettre à jour l'ID temporaire avec l'ID du joueur blessé créé sur le serveur
    const { id } = createPlayerInjuryResp.data.createPlayerInjury;
    createdPlayerInjury.id = id;
  
    // Mettre à jour l'état injuries avec l'objet mis à jour
    setInjuries((prevInjuries) =>
      prevInjuries.map((item) => (item.id === 'temp-id' ? createdPlayerInjury : item))
    );
  
    setModalOpen(false);
  };

  const CONTENT_MODAL = 
    <div className="modal-container">
      <CSelect
        label={`${t('team_user_profile.modals.0.inputs.0.label')} *`}
        data={injuries}
        selectCallback={selectInjury}
        className="input"
        value={injury}
      />
      <div className="input input-inline">
        <CDatePicker
          label={`${t('team_user_profile.modals.0.inputs.1.label')} *`}
          inputCallback={inputStartDate}
          defaultValue={startDate}
        />
        <TextInput
          className="input-right"
          label={`${t('team_user_profile.modals.0.inputs.2.label')} *`}
          inputCallback={inputDuration}
          value={duration}
        />
      </div>
      
      <CButton
        className="modal-btn"
        label={`${t('team_user_profile.modals.0.buttons.0.label')}`}
        type="contained"
        color={COLOR_PRIMARY}
        height={40}
        size={14}
        onClick={() => addInjury()}
      />
    </div>

  return(
    <div className="team-player-card">
      <div className="team-player-card-title">
        <BandageIcon className="team-player-card-title-icon" />
        <CText level="h3" fontWeight="700" color="light">
          {t('team_user_profile.contents.0.sub_contents.0.title')}
        </CText>
        <DotsVerticalIcon className="team-player-more-btn" onClick={(e) => moreBtnClick(e, true, false)}/>
      </div>

      <Grid container spacing={1} alignItems="flex-start">
        {isLoaded === true && playerInjuries?.map((playerInjury, index) => (
          <Grid container item xs={12} key={`injury-${index}`}>
            <div className="team-player-info">
              <div className="team-player-sub-title">
                <CText level="h3" fontWeight="700" color="light">
                  {playerInjury.label}
                </CText>
                <DotsVerticalIcon
                  className="team-player-more-btn"
                  onClick={(e) => moreBtnClick(e, false, true, playerInjury)}
                />
              </div>

              <div className="team-player-timeline">
                <CText className="team-player-timeline-label team-player-timeline-label-left" level="p2" fontWeight="700" color="light">{playerInjury.start_date}</CText>
                <div className="team-player-timeline-circle">
                  <div className="team-player-timeline-dot" />
                </div>
                <div className="team-player-timeline-line" />
                <div className="team-player-timeline-circle">
                  <div className="team-player-timeline-dot" />
                </div>
                <CText className="team-player-timeline-label team-player-timeline-label-right" level="p2" fontWeight="700" color="light">{playerInjury.end_date}</CText>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            minWidth: '10ch',
          },
        }}
        autoFocus={false}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {addBtn ?
          <MenuItem onClick={() => clickAdd()}>
            <span className="list-icon-zone">
              <AddIcon className="list-icon" />
            </span>
            {t('team_user_profile.contents.0.buttons.0.label')}
          </MenuItem> : null
        }
        {deleteBtn ?
          <MenuItem onClick={() => onClickDelete()}>
            <span className="list-icon-zone">
              <TrashIcon className="list-icon" />
            </span>
            {t('team_user_profile.contents.0.buttons.1.label')}
          </MenuItem>: null
        }
      </Menu>

      <CModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        content={CONTENT_MODAL}
      >
      </CModal>
    </div>
  )
}

const WeightEvolution = ({userId, season, t}) => {
  const [playerWeights, setPlayerWeights] = useState([]);
  const [weightData, setWeightData] = useState({dates: [], values: []});
  const [isLoaded, setIsLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState({type: "", value: false});

  useEffect(() => {  
    const fetchData = async () => {
      await listPlayerWeights()
      setIsLoaded(true)
    }
  
    fetchData()
  }, [])

  const listPlayerWeights = async () => {
    try {
      const filter = {
        user_id: { eq: userId },
        season: { eq: season },
      };
  
      let playerWeights = await API.graphql({
        query: queries.listWeights,
        variables: { filter: filter },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
  
      playerWeights = playerWeights?.data?.listWeights.items;
  
      playerWeights.sort((a, b) => {
        const dateComparison = new Date(a.date) - new Date(b.date);
        if (dateComparison !== 0) {
          return dateComparison;
        }
        return new Date(a.createdAt) - new Date(b.createdAt);
      });
  
      const formattedPlayerWeights = playerWeights.map((item) => ({
        ...item,
        date: formatDate(item.date, i18next.language)
      }));
  
      setPlayerWeights(formattedPlayerWeights);
  
      const weightData = formattedPlayerWeights.reduce((acc, item) => {
        acc.values.push(item.weight);
        acc.dates.push(item.date);
        return acc;
      }, { values: [], dates: [] });
  
      setWeightData(weightData);
    } catch (err) {
      console.log(err);
    }
  };
  
  //Menu
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl);
  const moreBtnClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null)
  };

  const clickAdd = async() => {
    handleClose()
    setModalOpen({type: "add", value: true})
  };

  const clickDelete = () => {
    handleClose()
    setModalOpen({type: "delete", value: true})
  };

  /********* Weight *********/
  const [weight, setWeight] = useState();
  const inputWeight = (data) => {
    const formattedData = data.replace(',', '.');
    const weight = parseFloat(formattedData);
  
    if (!isNaN(weight)) {
      setWeight(weight);
    } else {
      //console.error("Invalid input: not a valid number");
    }
  };

  const addPlayerWeight = async () => {
    const formatedDate = moment().format("YYYY-MM-DD");
  
    const playerWeightInput = {
      season: season,
      date: formatedDate,
      weight: weight,
      user_id: userId,
      userWeightsId: userId
    };

    const createdPlayerWeight = {
      ...playerWeightInput,
      id: 'temp-id',
      date: formatDate(formatedDate, i18next.language)
    };
  
    setPlayerWeights((prevPlayerWeights) => {
      const updatedWeights = [...prevPlayerWeights, createdPlayerWeight];
      return updatedWeights;
    });

    setWeightData((prevWeightData) => ({
      dates: [...prevWeightData.dates, formatDate(formatedDate, i18next.language)],
      values: [...prevWeightData.values, weight]
    }));
  
    // Créer l'objet playerInjury sur le serveur
    const createPlayerWeightResp = await API.graphql({
      query: mutations.createWeight,
      variables: { input: playerWeightInput },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
  
    // Mettre à jour l'ID temporaire avec l'ID du joueur blessé créé sur le serveur
    const { id } = createPlayerWeightResp.data.createWeight;
    createdPlayerWeight.id = id;
  
    // Mettre à jour l'état injuries avec l'objet mis à jour
    setPlayerWeights((prevWeights) =>
      prevWeights.map((item) => (item.id === 'temp-id' ? createdPlayerWeight : item))
    );
  
    setModalOpen({type: "", value: false});
  }

  const onClickDelete = async(id) => {
    const deleteInput = {
      id: id
    };

    await API.graphql({
      query: mutations.deleteWeight,
      variables: { input: deleteInput },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })

    const updatedPlayerWeights = playerWeights.filter((item) => item.id !== id);

    setPlayerWeights(updatedPlayerWeights);

    const weightData = updatedPlayerWeights.reduce((acc, item) => {
      acc.values.push(item.weight)
      acc.dates.push(item.date)
      return acc
    }, { values: [], dates: [] })

    setWeightData(weightData)
  }

  let CONTENT_MODAL
  if(modalOpen.type == "add") {
    CONTENT_MODAL =
    <div className="modal-container">
      <TextInput
        className="input"
        label={`${t('team_user_profile.modals.1.inputs.0.label')} *`}
        inputCallback={inputWeight}
        value={weight}
      />

      <CButton
        className="modal-btn"
        label={`${t('team_user_profile.modals.1.buttons.0.label')}`}
        type="contained"
        color={COLOR_PRIMARY}
        height={40}
        size={14}
        onClick={() => addPlayerWeight()}
      />
    </div>
  } else if(modalOpen.type == "delete") {
    CONTENT_MODAL =
    <div className="modal-container">
      <Grid container spacing={1} alignItems="flex-start">
        {playerWeights?.slice()?.reverse()?.map((playerWeight, index) => (
          <Grid container item xs={12} key={`weight-${index}`}>
            <div className="team-player-info">
              <CText className="team-player-sub-title" level="p2" fontWeight="700" color="light">
               {playerWeight.date}:
               <span
                className="team-player-cross-btn"
                onClick={() => onClickDelete(playerWeight.id)}
                >
                  <CrossIcon />
                </span>
              </CText>
              <CText level="p2" fontWeight="700" color="light">
                {playerWeight.weight} kg
              </CText>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  }

  const data = {
    labels: weightData.dates,
    datasets: [
      {
        type: 'line',
        label: ` ${t('team_user_profile.contents.0.sub_contents.1.labels.0')} (kg)`,
        fill: false,
        lineTension: 0.1,
        backgroundColor: COLOR_PURPLE,
        borderColor: COLOR_PURPLE,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 10.0,
        borderJoinStyle: 'miter',
        pointBorderColor: COLOR_PURPLE,
        pointBackgroundColor: COLOR_PURPLE,
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: COLOR_PURPLE,
        pointHoverBorderColor: COLOR_PURPLE_TRANSPARENT,
        pointHoverBorderWidth: 10,
        pointWidth: 100,
        pointRadius: 4,
        pointHitRadius: 10,
        data: weightData.values
      }
    ]
  };

  let minValue = Math.floor(Math.min(...weightData.values)) - 10;
  if (minValue < 0) {
    minValue = 0
  }

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1,
    scales: {
      y: {
        ticks: {
          stepSize: 10
        },
        min: minValue
      }
    }
  };

  return(
    <div className="team-player-card">
      <div className="team-player-card-title">
        <WeightIcon className="team-player-card-title-icon" />
        <CText level="h3" fontWeight="700" color="light">
          {t('team_user_profile.contents.0.sub_contents.1.title')}
        </CText>
        <DotsVerticalIcon className="team-player-more-btn" onClick={moreBtnClick} />
      </div>

      {isLoaded ?
        <Chart data={data} options={options} className="team-player-chart" /> : null
      }

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            minWidth: '10ch',
          },
        }}
        autoFocus={false}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <MenuItem onClick={() => clickAdd()}>
          <span className="list-icon-zone">
            <AddIcon className="list-icon" />
          </span>
          {t('team_user_profile.contents.0.buttons.0.label')}
        </MenuItem>
        <MenuItem onClick={() => clickDelete()}>
          <span className="list-icon-zone">
            <TrashIcon className="list-icon" />
          </span>
          {t('team_user_profile.contents.0.buttons.1.label')}
        </MenuItem>
      </Menu>
      <CModal
        className="modal"
        open={modalOpen.value}
        onClose={() => setModalOpen({type:"", value: false})}
        content={CONTENT_MODAL}
      >
      </CModal>
    </div>
  )
}

const FatMassEvolution = ({userId, season, t}) => {
  const [playerBodyFats, setPlayerBodyFats] = useState([]);
  const [bodyFatData, setBodyFatData] = useState({dates: [], values: []});
  const [isLoaded, setIsLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState({type: "", value: false});

  useEffect(() => {  
    const fetchData = async () => {
      await listPlayerBodyFats()
      setIsLoaded(true)
    }
  
    fetchData()
  }, [])

  const listPlayerBodyFats = async () => {
    try {
      const filter = {
        user_id: { eq: userId },
        season: { eq: season }
      };
  
      let playerBodyFats = await API.graphql({
        query: queries.listBodyFats,
        variables: { filter: filter },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
  
      playerBodyFats = playerBodyFats?.data?.listBodyFats.items;
  
      playerBodyFats.sort((a, b) => {
        const dateComparison = new Date(a.date) - new Date(b.date);
        if (dateComparison !== 0) {
          return dateComparison;
        }
        return new Date(a.createdAt) - new Date(b.createdAt);
      });
  
      const formattedPlayerBodyFats = playerBodyFats.map((item) => ({
        ...item,
        date: formatDate(item.date, i18next.language)
      }));
  
      setPlayerBodyFats(formattedPlayerBodyFats);
  
      const bodyFatData = formattedPlayerBodyFats.reduce((acc, item) => {
        acc.values.push(item.body_fat_percentage);
        acc.dates.push(item.date);
        return acc;
      }, { values: [], dates: [] });
  
      setBodyFatData(bodyFatData);
  
    } catch (err) {
      console.log(err);
    }
  };

  //Menu
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl);
  const moreBtnClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null)
  };

  const clickAdd = async() => {
    handleClose()
    setModalOpen({type: "add", value: true})
  };

  const clickDelete = () => {
    handleClose()
    setModalOpen({type: "delete", value: true})
  };

  /********* BodyFat *********/
  const [bodyFat, setBodyFat] = useState();
  const inputBodyFat = (data) => {
    const formattedData = data.replace(',', '.');
    const bodyFatValue = parseFloat(formattedData);
  
    if (!isNaN(bodyFatValue)) {
      setBodyFat(bodyFatValue);
    } else {
      //console.error("Invalid input: not a valid number");
    }
  };

  const addPlayerBodyFat = async () => {
    const formatedDate = moment().format("YYYY-MM-DD");
  
    const playerBodyFatInput = {
      body_fat_percentage: bodyFat,
      date: formatedDate,
      season: season,
      user_id: userId,
      userBody_fatsId: userId
    }

    const createdPlayerBodyFat = {
      ...playerBodyFatInput,
      id: 'temp-id',
      date: formatDate(formatedDate, i18next.language)
    };
  
    setPlayerBodyFats((prevPlayerBodyFat) => {
      const updatedPlayerBodyFat = [...prevPlayerBodyFat, createdPlayerBodyFat];
      return updatedPlayerBodyFat;
    });

    setBodyFatData((prevPlayerBodyFat) => ({
      dates: [...prevPlayerBodyFat.dates, formatDate(formatedDate, i18next.language)],
      values: [...prevPlayerBodyFat.values, bodyFat]
    }));
  
    // Créer l'objet playerInjury sur le serveur
    const createPlayerBodyFatResp = await API.graphql({
      query: mutations.createBodyFat,
      variables: { input: playerBodyFatInput },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
  
    // Mettre à jour l'ID temporaire avec l'ID du joueur blessé créé sur le serveur
    const { id } = createPlayerBodyFatResp.data.createBodyFat;
    createdPlayerBodyFat.id = id;
  
    // Mettre à jour l'état injuries avec l'objet mis à jour
    setPlayerBodyFats((prevPlayerBodyFats) =>
      prevPlayerBodyFats.map((item) => (item.id === 'temp-id' ? createdPlayerBodyFat : item))
    );
  
    setModalOpen({type: "", value: false});
  }

  const onClickDelete = async(id) => {
    const deleteInput = {
      id: id
    };

    await API.graphql({
      query: mutations.deleteBodyFat,
      variables: { input: deleteInput },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })

    const updatedPlayerBodyFats = playerBodyFats.filter((item) => item.id !== id);

    setPlayerBodyFats(updatedPlayerBodyFats);

    const bodyFatData = updatedPlayerBodyFats.reduce((acc, item) => {
      acc.values.push(item.body_fat_percentage)
      acc.dates.push(item.date)
      return acc
    }, { values: [], dates: [] })

    setBodyFatData(bodyFatData)
  }

  let CONTENT_MODAL
  if(modalOpen.type == "add") {
    CONTENT_MODAL =
    <div className="modal-container">
      <TextInput
        className="input"
        label={`${t('team_user_profile.modals.2.inputs.0.label')} *`}
        inputCallback={inputBodyFat}
        value={bodyFat}
      />

      <CButton
        className="modal-btn"
        label={`${t('team_user_profile.modals.2.buttons.0.label')}`}
        type="contained"
        color={COLOR_PRIMARY}
        height={40}
        size={14}
        onClick={() => addPlayerBodyFat()}
      />
    </div>
  } else if(modalOpen.type == "delete") {
    CONTENT_MODAL =
    <div className="modal-container">
      <Grid container spacing={1} alignItems="flex-start">
        {playerBodyFats?.slice()?.reverse()?.map((playerBodyFat, index) => (
          <Grid container item xs={12} key={`body-fat-${index}`}>
            <div className="team-player-info">
              <CText className="team-player-sub-title" level="p2" fontWeight="700" color="light">
               {playerBodyFat.date}:
               <span
                  className="team-player-cross-btn"
                  onClick={() => onClickDelete(playerBodyFat.id)}
                >
                  <CrossIcon />
                </span>
              </CText>
              <CText level="p2" fontWeight="700" color="light">
                {playerBodyFat.body_fat_percentage} %
              </CText>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  }

  const data = {
    labels: bodyFatData.dates,
    datasets: [
      {
        type: 'line',
        label: ` ${t('team_user_profile.contents.0.sub_contents.2.labels.0')}`,
        fill: false,
        lineTension: 0.1,
        backgroundColor: COLOR_BLUE,
        borderColor: COLOR_BLUE,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 10.0,
        borderJoinStyle: 'miter',
        pointBorderColor: COLOR_BLUE,
        pointBackgroundColor: COLOR_BLUE,
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: COLOR_BLUE,
        pointHoverBorderColor: COLOR_BLUE_TRANSPARENT,
        pointHoverBorderWidth: 10,
        pointWidth: 100,
        pointRadius: 4,
        pointHitRadius: 10,
        data: bodyFatData.values
      }
    ],
  };

  let minValue = Math.floor(Math.min(...bodyFatData.values)) - 10;
  if (minValue < 0) {
    minValue = 0
  }

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1,
    scales: {
      y: {
        ticks: {
          stepSize: 10
        },
        min: minValue
      }
    }
  };

  return(
    <div className="team-player-card">
      <div className="team-player-card-title">
        <FatMassIcon className="team-player-card-title-icon" />
        <CText level="h3" fontWeight="700" color="light">
          {t('team_user_profile.contents.0.sub_contents.2.title')}
        </CText>
        <DotsVerticalIcon className="team-player-more-btn" onClick={moreBtnClick}/>
      </div>

      {isLoaded ?
        <Chart data={data} options={options} className="team-player-chart" /> : null
      }

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            minWidth: '10ch',
          },
        }}
        autoFocus={false}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={() => clickAdd()}>
          <span className="list-icon-zone">
            <AddIcon className="list-icon" />
          </span>
          {t('team_user_profile.contents.0.buttons.0.label')}
        </MenuItem>
        <MenuItem onClick={() => clickDelete()}>
          <span className="list-icon-zone">
            <TrashIcon className="list-icon" />
          </span>
          {t('team_user_profile.contents.0.buttons.1.label')}
        </MenuItem>
      </Menu>
      <CModal
        className="modal"
        open={modalOpen.value}
        onClose={() => setModalOpen({type:"", value: false})}
        content={CONTENT_MODAL}
      >
      </CModal>
    </div>
  )
}

const TeamPlayer = (props) => {
  const { t, i18n } = useTranslation()

  const [userId, setUserId] = useState({});
  const [user, setUser] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [season, setSeason] = useState(null)

  // Get User
  const userStored = useSelector((state) => state?.user)
  const userPhotoStored = userStored.photo_path

  useEffect(() => {
    const fetchData = async () => {
      const userId = await getUserId();
      await getUserInfo(userId);
      setUserId(userId);
      const currentSeason = await getCurrentSeason()
      setSeason(currentSeason)
      setIsLoaded(true)
    }
  
    fetchData();
  }, [userStored])

  const getUserId = async () => {
    try {
      const url = window.location.pathname;
      const parts = url.split('/');
      const playerId = parts[parts.length - 1];
  
      return playerId;
    } catch (err) {
      console.log(err);
    }
  };

  const getUserInfo = async (userId) => {
    try {
      let user = await API.graphql({
        query: queries.getUser,
        variables: { id: userId },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })
      user = user?.data?.getUser
      setUser(user)
    } catch (err) {
      console.log(err);
    }
  };

  const MENU = [
    {
      icon: <MedicalKitIcon />,
      label: t('team_user_profile.contents.0.title')
    }
  ]

  return (
    <div className="team-player-container">
      {isLoaded ?
        <div>
          <div className="team-player-user-info">
            <ProfilePicture
              className="team-player-profile-picture"
              src={user?.photo_path}
            />
            <div>
              <CText level="h2" color="light" className="team-player-user-name">
                {user.first_name} {user.last_name}
              </CText>
            </div>
          </div>
          
          <div className="team-player-title">
            <div className="team-player-title-icon">
              {MENU[0].icon}
            </div>
            <CText
              level="h1"
              color="light"
              fontWeight={700}
            >
              {MENU[0].label} 
            </CText>
          </div>

          <Grid container spacing={1} alignItems="flex-start">
            <Grid container item xs={12} alignItems="center">
              <Injuries userId={userId} season={season} t={t} />
            </Grid>
            <Grid container item xs={12} alignItems="center">
              <WeightEvolution userId={userId} season={season} t={t} />
            </Grid>
            <Grid container item xs={12} alignItems="center">
              <FatMassEvolution userId={userId} season={season} t={t} />
            </Grid>
          </Grid>
        </div>
        : null
      }
    </div>
  )
}

export default TeamPlayer;