import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { CText } from "../../components/Text";
import NoDocumentsCard from "../../components/Cards/NoDocumentsCard";
import Document from './Document';
import './documents.css';

const Documents = () => {
  const { t } = useTranslation();

  // Accès aux documents stockés dans Redux
  const documentsFromRedux = useSelector(state => state.documents.documents);

  // État local pour les documents
  const [documents, setLocalDocuments] = useState([]);

  // Initialisez l'état local à partir de Redux
  useEffect(() => {
    if (documentsFromRedux.length > 0) {
      setLocalDocuments(documentsFromRedux);
    }
  }, [documentsFromRedux]);

  // Optionnel : Ajoutez une fonction pour mettre à jour l'état local
  const handleDocumentUpdate = (updatedDocument) => {
    setLocalDocuments((prevDocuments) =>
      prevDocuments.map((doc) =>
        doc.id === updatedDocument.id && doc.type === updatedDocument.type
          ? updatedDocument
          : doc
      )
    );
  };

  return (
    <div className="documents-container">
      <CText className="documents-title" level="h1" color="light">
        {t('documents.title')}
      </CText>

      <Grid container spacing={1}>
        {documents.length > 0 ? (
          documents.map((document, index) => (
            <Document
              key={index}
              data={document}
              index={index}
              onUpdate={handleDocumentUpdate}
            />
          ))
        ) : (
          <div className="documents-no-documents">
            <NoDocumentsCard />
          </div>
        )}
      </Grid>
    </div>
  );
};

export default Documents;