import moment from 'moment';
import { deDE, frFR, esES, itIT, enUS } from '@mui/x-date-pickers/locales';

const germanLocale = deDE.components.MuiLocalizationProvider.defaultProps.localeText;
const frenchLocale = frFR.components.MuiLocalizationProvider.defaultProps.localeText;
const spanishLocale = esES.components.MuiLocalizationProvider.defaultProps.localeText;
const italianLocale = itIT.components.MuiLocalizationProvider.defaultProps.localeText;
const englishLocale = enUS.components.MuiLocalizationProvider.defaultProps.localeText;

export const formatMessageDate = (date, locale = 'fr') => {
  // Vérifie si la date est valide
  if (!date || isNaN(new Date(date).getTime())) {
    return ''; // Retourne une chaîne vide ou un message par défaut si la date est invalide
  }

  const now = new Date();
  const messageDate = new Date(date);

  // Vérifie si la date du message est aujourd'hui
  const isToday =
    now.getDate() === messageDate.getDate() &&
    now.getMonth() === messageDate.getMonth() &&
    now.getFullYear() === messageDate.getFullYear();

  if (isToday) {
    // Si la date est aujourd'hui, afficher seulement l'heure dans le format approprié
    return new Intl.DateTimeFormat(locale, {
      hour: '2-digit',
      minute: '2-digit'
    }).format(messageDate);
  }

  // Calculer la différence en jours
  const differenceInDays = Math.floor(
    (now - messageDate) / (1000 * 60 * 60 * 24)
  );

  if (differenceInDays < 7) {
    // Si la date est dans les 7 jours précédents, afficher le jour et l'heure
    return new Intl.DateTimeFormat(locale, {
      weekday: 'long',
      hour: '2-digit',
      minute: '2-digit'
    }).format(messageDate);
  }

  // Sinon, afficher la date complète et l'heure
  return new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  }).format(messageDate);
};

export const formatTime = (date, format) => {
  if (!date) return '';

  const time = new Date(date);
  const hours = time.getHours();
  const minutes = time.getMinutes();
  
  if (format === 'HH:mm') {
    // Format 24 heures
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  } else if (format === 'hh:mm a') {
    // Format 12 heures avec AM/PM
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const hour12 = hours % 12 || 12;
    return `${hour12.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${ampm}`;
  }
  
  return '';
};

export const handleDateInputChange = (event, dateFormat, callback) => {
  let { value } = event.target;

  // Supprime tout caractère non autorisé sauf les chiffres et "/"
  value = value.replace(/[^0-9/]/g, "");

  // Limite stricte à 10 caractères (format DD/MM/YYYY ou MM/DD/YYYY)
  if (value.length > 10) {
    value = value.slice(0, 10);
  }

  let formattedValue = "";

  // Divise les parties par "/"
  const parts = value.split("/");
  let first = parts[0] || ""; // Peut être jour ou mois selon le format
  let second = parts[1] || ""; // Peut être mois ou jour selon le format
  let year = parts[2] || "";

  // Détermine si le format commence par le jour (DD/MM/YYYY) ou par le mois (MM/DD/YYYY)
  const isDayFirst = dateFormat.toLowerCase().startsWith("dd");

  // Gestion de la première partie (jour ou mois)
  if (first.length > 2) {
    first = first.slice(0, 2);
  }
  if (first.length === 2) {
    first = parseInt(first, 10) > (isDayFirst ? 31 : 12) ? (isDayFirst ? "31" : "12") : first;
  }

  // Gestion de la deuxième partie (mois ou jour)
  if (second.length > 2) {
    second = second.slice(0, 2);
  }
  if (second.length === 2) {
    second = parseInt(second, 10) > (isDayFirst ? 12 : 31) ? (isDayFirst ? "12" : "31") : second;
  }

  // Gestion de l'année
  if (year.length > 4) {
    year = year.slice(0, 4);
  }

  // Reconstruit la valeur formatée avec "/"
  if (first) {
    formattedValue += first;
    if (first.length === 2) {
      formattedValue += "/";
    }
  }
  if (second) {
    formattedValue += second;
    if (second.length === 2) {
      formattedValue += "/";
    }
  }
  if (year) {
    formattedValue += year;
  }

  // Suppression automatique des "/" si une partie devient incomplète
  if (value.endsWith("/") && formattedValue[formattedValue.length - 1] !== "/") {
    formattedValue += "/";
  }

  // Validation globale de la date
  if (formattedValue.length === 10) {
    const isValid = moment(formattedValue, dateFormat, true).isValid();
    if (!isValid) {
      console.error("Date invalide");
    }
  }

  // Mise à jour de l'input
  if (callback) {
    callback(formattedValue);
  }
};

export const getTimeFormat = (language) => {
  switch (language) {
    case 'fr':
    case 'es':
      return 'HH:mm';
    case 'en':
      return 'hh:mm a';
    default:
      return 'HH:mm';
  }
};

export const getDateFormat = (language) => {
  let res;
  switch (language) {
    case 'es':
      res = spanishLocale;

      return res;
    case 'fr':
      res = frenchLocale;

      return res;
    case 'it':
      res = italianLocale;

      return res;
    case 'en':
      res = englishLocale;

      return res;
    default:
      return englishLocale;
  }
};