import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { API } from 'aws-amplify';
import { useNavigate } from "react-router-dom";
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { BadRequestSnackBar } from "../../components/SnackBar";
import CModal from "../../components/Modal";
import { CText } from "../../components/Text";
import ChipFilter from "../../components/Chip/ChipFilter";
import Scheduler from "./Scheduler";
import DropdownFilter from "../../components/DropdownSelector/DropdownFilter";
import MatchCard from '../../components/Cards/MatchCard';
import TrainingCard from '../../components/Cards/TrainingCard';
import moment from 'moment';
import NoTeamsCard from "../../components/Cards/NoTeamsCard";
import NoCompetitionsCard from "../../components/Cards/NoCompetitionsCard";
import { useStyles } from './calendar.styles';
import TeamsSelector from '../../components/DropdownSelector/TeamsSelector';
import CompetitionsSelector from '../../components/DropdownSelector/CompetitionsSelector';
import { getTeamIdFromUrlParams } from '../../utils/team';
import { getCurrentSeason } from "../../utils/season";
import { ReactComponent as CalendarFilterIcon } from '../../assets/icons/calendar-filter.svg';
import { ReactComponent as ListIcon } from '../../assets/icons/list.svg';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus-thin.svg';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import EventFormModal from './EventFormModal';
import { ReactComponent as PitchIcon } from '../../assets/icons/pitch.svg';
import { ReactComponent as TrainingIcon } from '../../assets/icons/training.svg';
import Fab from "../../components/Fab/index";
import './calendar.css';

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK= "#e92163";
const COLOR_BLUE = "#25a5e6";
const COLOR_DARK = '#242834';
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;

const mapEventsToSchedulerFormat = (events, eventTypes, t) => {
  return events.map((event) => {
    if (event.__typename === 'Match') {
      const homeTeamName = event?.home_team_name || 
        (event?.home_team?.club?.names?.length ? JSON.parse(event.home_team.club.names[0]).name : "Unknown Team");

      const awayTeamName = event?.away_team_name || 
        (event?.away_team?.club?.names?.length ? JSON.parse(event.away_team.club.names[0]).name : "Unknown Team");

      return {
        id: event.id,
        title: `${homeTeamName} - ${awayTeamName}`,
        start: new Date(`${event.date}T${event.hour || '00:00'}`),
        end: new Date(moment(`${event.date}T${event.hour || '00:00'}`).add(90, 'minutes').toDate())
      };
    } else if (event.__typename === 'Training') {
      return {
        id: event.id,
        title: t('common.training'),
        start: new Date(`${event.date}T${event.start_time || '00:00'}`),
        end: new Date(`${event.date}T${event.end_time || '00:00'}`)
      };
    }
    return null;
  }).filter(Boolean);
};

const Calendar = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const userStored = useSelector(state => state?.user);
  const navigate = useNavigate();

  const EVENT_TYPES = [
    { "code": "Match", "icon": <PitchIcon />, "label": t('calendar.labels.6.label'), selected: true },
    { "code": "Training", "icon": <TrainingIcon />, "label": t('calendar.labels.7.label'), selected: true }
  ];

  const [modalOpen, setModalOpen] = useState({value: false});
  const [formType, setFormType] = useState('update');
  const [season, setSeason] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [teams, setTeams] = useState([]);
  const [teamIndex, setTeamIndex] = useState(0);
  const [competitions, setCompetitions] = useState([]);
  const [competitionIndex, setCompetitionIndex] = useState(0);
  const [events, setEvents] = useState([]);
  const [schedulerEvents, setSchedulerEvents] = useState([]);
  const [editable, setEditable] = useState(false);
  const [selectedMatchData, setSelectedEventData] = useState(null);
  const [selectedEventIndex, setSelectedEventIndex] = useState(null);
  const [dropdownCalendarTypeIndex, setDropdownCalendarTypeIndex] = useState(0);
  const [dropdownCalendarViewIndex, setDropdownCalendarViewIndex] = useState(0);
  const [eventTypes, setEventTypes] = useState(EVENT_TYPES);
  const [hasScrolled, setHasScrolled] = useState(false);

  const mergeAndSortMatchesTrainings = async (matches = [], training = []) => {
    const mergedList = [...matches, ...training];
  
    const sortedList = mergedList.sort((a, b) => {
      const dateComparison = new Date(a.date) - new Date(b.date);
      if (dateComparison !== 0) {
        return dateComparison;
      }
  
      const timeA = a.hour ? a.hour.split(':').map(Number) : (a.start_time ? a.start_time.split(':').map(Number) : [0, 0]);
      const timeB = b.hour ? b.hour.split(':').map(Number) : (b.start_time ? b.start_time.split(':').map(Number) : [0, 0]);
  
      return timeA[0] - timeB[0] || timeA[1] - timeB[1];
    });
  
    setEvents(sortedList);
  
    const filteredList = sortedList.filter((event) => {
      const eventType = eventTypes.find(type => type.code === event.__typename);
      return eventType && eventType.selected;
    });
  
    setSchedulerEvents(mapEventsToSchedulerFormat(filteredList, eventTypes, t));
  };

  useEffect(() => {
    if (events.length > 0 && !hasScrolled) {
      const today = new Date();
  
      // Trouver l'index du prochain événement ou d'un événement futur
      const nextEventIndex = events.findIndex(event => new Date(event.date) >= today);
  
      setTimeout(() => {
        if (nextEventIndex !== -1) {
          scrollToEvent(nextEventIndex);
        } else {
          scrollToEvent(events.length - 1);
        }
        setHasScrolled(true);
      }, 100);
    }
  }, [events]);
  
  
  const scrollToEvent = (index) => {
    const eventElement = document.getElementById(`event-${index}`);
    if (eventElement) {
      eventElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  useEffect(() => {  
    const fetchData = async () => {
      await getUserType()
      const currentSeason = await getCurrentSeason();
      setSeason(currentSeason);
      let teams = await getTeams();
      let matches = [];
      let training = [];
      let foundTeamIndex = 0;

      if (teams?.length > 0) {
        const teamId = await getTeamIdFromUrlParams(window.location.pathname, teams);
        if(teamId) {
          foundTeamIndex = teams.findIndex(team => team.id === teamId);
          setTeamIndex(foundTeamIndex);
        }

        let competitionsList = await getCompetitions(currentSeason, teams, foundTeamIndex)
        if(competitionsList?.length > 0) {
          matches = await getTeamMatches(currentSeason, teams, foundTeamIndex, competitionsList, 0)
        }

        training = await getTeamTraining(currentSeason, teams, foundTeamIndex);

        if (matches?.length > 0 || training?.length > 0) {
          mergeAndSortMatchesTrainings(matches, training);
        }
      }
      setLoaded(true)
    }
  
    fetchData();
  }, []);

  useEffect(() => {
    const filteredAndSortedEvents = async () => {
      // Supposons que matches et trainings soient des données disponibles dans l'état
      const matches = events.filter(event => event.__typename === 'Match');
      const trainings = events.filter(event => event.__typename === 'Training');
      
      // Appeler la fonction mergeAndSortMatchesTrainings avec les nouveaux eventTypes
      await mergeAndSortMatchesTrainings(matches, trainings);
    };
  
    filteredAndSortedEvents();
  }, [eventTypes]);

  const calendarTypes = [
    { "code": "list", "name": t('calendar.labels.1.label'), "icon": <ListIcon /> },
    { "code": "calendar", "name": t('calendar.labels.2.label'), "icon": <CalendarFilterIcon /> }
  ];

  const calendarViews = [
    { "code": 'month', "name": t('calendar.labels.3.label') },
    { "code": 'week', "name": t('calendar.labels.4.label') },
    { "code": 'day', "name": t('calendar.labels.5.label') }
  ];

  const getUserType = async() => {
    try {
      const groups = userStored?.groups
      if(groups.includes("Coaches") || groups.includes("ClubAdmins")) {
        setEditable(true)
      }
    }
    catch(err) {
      //console.log(err)
    }
  }

  const getTeams = async() => {
    try {
      const { groups, club } = userStored;
      let teams = [];
    
      if (groups.includes("ClubAdmins")) {
        const clubId = club?.id;

        const teamsFilter = {
          club_id: { eq: clubId },
          deleted: {eq: false}
        }
  
        let teamsList = await API.graphql({
          query: queries.listClubTeams,
          variables: { filter: teamsFilter },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        })
        teamsList = teamsList.data.listTeams.items

        teams = teamsList;

        const teamsListOrdered = teamsList.sort((a, b) => {
          const numberA = parseInt(a.name.replace(/\D/g, ''), 10) || 0;
          const numberB = parseInt(b.name.replace(/\D/g, ''), 10) || 0;
          return numberA - numberB;
        });
  
        teams = teamsListOrdered;
      } else {
        const currentSeason = await getCurrentSeason();
        const userId = userStored.info.id;

        const filter = {
          season: { eq: currentSeason },
          user_id: { eq: userId },
          invite_status: { eq: 1 }
        };
    
        let userTeams = await API.graphql({
          query: queries.listInvitations,
          variables: { filter: filter },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });

        userTeams = userTeams.data.listInvitations.items;

        userTeams = userTeams?.map(invitation => ({
          id: invitation.team.id,
          name: invitation.team.name,
          club: {
            id: invitation.team.club.id,
            name: JSON.parse(invitation.team.club.names[0]).name,
            logo: invitation.team.club.logo
          }
        }));
        
        userTeams = userTeams.sort((a, b) => {
          const clubNameComparison = a.club.name.localeCompare(b.club.name);
          if (clubNameComparison !== 0) {
            return clubNameComparison;
          }

          return a.name.localeCompare(b.name);
        });

        teams = userTeams;
      }

      setTeams(teams);

      return teams;
    }
    catch(err) {
      //console.log(err)
    }
  }

  const getCompetitions = async(currentSeason, teams, teamIndex) => {
    try {
      const { groups } = userStored;
      let competitionArray = [];
      let teamCompetitions;

      if (groups.includes("ClubAdmins")) {
        teamCompetitions = teams[teamIndex]?.competitions?.items

        await teamCompetitions?.map(data => {
          competitionArray.push(data.competition);
        });
      } else if (groups.includes("Coaches") || groups.includes("Players")) {
        const competitionFilter = {
          season: { eq: currentSeason }
        }
  
        const getTeamResp = await API.graphql({
          query: queries.getTeamWithCompetitions,
          variables: { 
            id: teams[0].id,
            competitionFilter: competitionFilter
          }, authMode: 'AMAZON_COGNITO_USER_POOLS'
        })
        teamCompetitions = getTeamResp.data.getTeam.competitions.items

        await teamCompetitions.map(data => {
          competitionArray.push(data.competition);
        });
      }

      const competitionsListOrdered = competitionArray.sort((a, b) => {
        const numberA = parseInt(a.name.replace(/\D/g, ''), 10) || 0;
        const numberB = parseInt(b.name.replace(/\D/g, ''), 10) || 0;
        return numberA - numberB;
      });

      const competitions = competitionsListOrdered;

      setCompetitions(competitions);

      return competitions;
    }
    catch(err) {
      //console.log(err)
    }
  }

  const getTeamMatches = async(season, teams, teamIndex, competitionsList, currentCompetitionIndex) => {
    try {
      const matchFilterSort = { field: "date", direction: "asc" };
      const matchFilter = {
        or: [
          { away_team_id: { eq: teams[teamIndex].id }},
          { home_team_id: { eq: teams[teamIndex].id }}
        ],
        competition_id: { eq: competitionsList[currentCompetitionIndex].id },
        season: { eq: season }
      };
  
      const searchMatchesResp = await API.graphql({
        query: queries.searchMatches,
        variables: { filter: matchFilter, sort: matchFilterSort },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      
      let searchMatches = searchMatchesResp?.data?.searchMatches?.items;
  
      if (searchMatches) {
        searchMatches = searchMatches.sort((a, b) => {
          const dateComparison = new Date(a.date) - new Date(b.date);
          if (dateComparison !== 0) {
            return dateComparison;
          }
  
          const timeA = a.hour ? a.hour.split(':').map(Number) : [0, 0];
          const timeB = b.hour ? b.hour.split(':').map(Number) : [0, 0];
          return timeA[0] - timeB[0] || timeA[1] - timeB[1];
        });
      }

      return searchMatches;
    }
    catch(err) {
      //console.log(err);
    }
  };

  const getTeamTraining = async(season, teams, teamIndex) => {
    try {
      const trainingFilter = {
        team_id: { eq: teams[teamIndex].id },
        season: { eq: season }
      };
  
      let listTraining = await API.graphql({
        query: queries.listTrainings,
        variables: { filter: trainingFilter },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      
      listTraining = listTraining?.data?.listTrainings?.items;
  
      if(listTraining) {
        listTraining = listTraining.sort((a, b) => {
          const dateComparison = new Date(a.date) - new Date(b.date);
          if (dateComparison !== 0) {
            return dateComparison;
          }
  
          const timeA = a.start_time ? a.start_time.split(':').map(Number) : [0, 0];
          const timeB = b.start_time ? b.start_time.split(':').map(Number) : [0, 0];
          return timeA[0] - timeB[0] || timeA[1] - timeB[1];
        });
      }
  
      return listTraining;
    }
    catch(err) {
      //console.log(err);
    }
  };
  
  const onClickModalAdd = (data) => {
    if(data) {
      setSelectedEventData(data);
    }

    setModalOpen({value: true});
    setFormType('create');
  }

  // Add Event
  const addEvent = (newEvent) => {
    setEvents((prevEvents) => {
      const newEvents = [...prevEvents];
      for (let i = 0; i < newEvents.length; i++) {
        if (newEvent.date.localeCompare(newEvents[i].date) === -1) {
          newEvents.splice(i, 0, newEvent);
          setSchedulerEvents(mapEventsToSchedulerFormat(newEvents, eventTypes, t));
          return newEvents;
        }
      }
      newEvents.push(newEvent);
      setSchedulerEvents(mapEventsToSchedulerFormat(newEvents, eventTypes, t));
      return newEvents;
    });
  };
  
  // Update Event
  const updateEventCB = (updatedEvent) => {
    setEvents((prevEvents) => {
      const newEvents = prevEvents.map((event) =>
        event.id === updatedEvent.id ? updatedEvent : event
      );
      setSchedulerEvents(mapEventsToSchedulerFormat(newEvents, eventTypes, t));
      return newEvents;
    });
  };

  //Delete Event
  const removeEvent = async (index) => {
    const eventToRemove = events[index];
    try {
      if (eventToRemove.__typename === 'Match') {
        await API.graphql({
          query: mutations.deleteMatch,
          variables: { input: { id: eventToRemove.id } },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
      } else if(eventToRemove.__typename === 'Training') {
        await API.graphql({
          query: mutations.deleteTraining,
          variables: { input: { id: eventToRemove.id } },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
      }
      
      setEvents((prevEvents) => {
        const newEvents = prevEvents.filter((_, i) => i !== index);
        setSchedulerEvents(mapEventsToSchedulerFormat(newEvents, eventTypes, t));
        return newEvents;
      });
    } catch (err) {
      //console.error("Error deleting match:", err);
    }
  };

  const handleDropdownCalendarType = (index) => {
    setDropdownCalendarTypeIndex(index);
  };

  const handleDropdownCalendarView = (index) => {
    setDropdownCalendarViewIndex(index);
  };

  const handleViewChange = (view) => {
    const index = calendarViews.findIndex(calendarView => calendarView.code === view);
    
    if (index !== -1) {
      setDropdownCalendarViewIndex(index);
    }
  };

  /********* Handle Error *********/
  const [openSnack, setOpenSnack] = useState(false)
  const [snackBarErrorTitle, setSnackBarErrorTitle] = useState("")
  const [snackBarErrorMessage, setSnackBarErrorMessage] = useState("")
  const [snackBarErrorElmts, setSnackBarErrorElmts] = useState("")

  const errorCB = (data) => {
    setOpenSnack(true);
    setSnackBarErrorTitle(data.errorTitle);
    setSnackBarErrorMessage(data.errorMessage);
    setSnackBarErrorElmts(data.errorElmts);
  }

  const closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
    setTimeout(() => {
      setSnackBarErrorMessage("");
      setSnackBarErrorElmts("");
    }, 150);
  }

  const onCloseModal = () => {
    setModalOpen({value: false});
    setFormType('create');
    setSelectedEventData(null);
    setSelectedEventIndex(null);
  }

  // Content Modal
  const CONTENT_MODAL =
    <EventFormModal
      team={teams[teamIndex]}
      competition={competitions[competitionIndex]}
      modalOpenCallback={(data) => setModalOpen(data)}
      data={selectedMatchData}
      index={selectedEventIndex}
      addEventCallback={addEvent}
      updateEventCallback={updateEventCB}
      errorCallback={errorCB}
      formType={formType}
    />

  // Handle change team/competition
  const selectTeam = async(index) => {
    let matches = [];
    await setTeamIndex(index);
    let competitionsList = await getCompetitions(season, teams, index);

    await setCompetitionIndex(0);
    await setCompetitions(competitionsList);

    if(competitionsList.length > 0) {
      matches = await getTeamMatches(season, teams, index, competitionsList, competitionIndex);
    }
    const training = await getTeamTraining(season, teams, index);
    mergeAndSortMatchesTrainings(matches, training);

    const teamName = teams[index].name;
    const currentPath = window.location.pathname;
    const url = new URL(currentPath, window.location.origin);
    url.searchParams.set('team', teamName);
    const finalPath = `${url.pathname}${url.search}`;
    navigate(finalPath, { replace: true });
  }

  const selectCompetition = async(index) => {
    setCompetitionIndex(index);
    const matches = await getTeamMatches(season, teams, teamIndex, competitions, index)
    const training = await getTeamTraining(season, teams, teamIndex);
    mergeAndSortMatchesTrainings(matches, training);
  }

  // Handle Match modification
  const handleClickUpdateEvent = async(index) => {
    setSelectedEventData(events[index],);
    setSelectedEventIndex(index);
    setModalOpen({value: true});
    setFormType('update');
  }

  const onChangeEventTypes = (data) => {
    setEventTypes(data);
  }

  const matchCardClick = (matchId) => {
    navigate(`/matches/${matchId}`)
  }

  //Matches Content
  let EVENTS_CONTENT = null;

  // Export calendar
  const generateICSFile = () => {
    let icsContent = `
    BEGIN:VCALENDAR
    VERSION:2.0
    CALSCALE:GREGORIAN
    PRODID:-//YourApp//EN
    `;

    events.forEach((event) => {
      const eventStartDate = new Date(event.date + ' ' + (event.hour || event.start_time));
      const endDate = new Date(eventStartDate.getTime() + 2 * 60 * 60 * 1000);

      icsContent += `
      BEGIN:VEVENT
      SUMMARY:${event.__typename} - ${event.name || 'Unnamed Event'}
      DTSTART:${formatDateToICS(eventStartDate)}
      DTEND:${formatDateToICS(endDate)}
      DESCRIPTION:${event.__typename} - ${event.name || 'No description'}
      END:VEVENT
      `;
    });

    icsContent += `END:VCALENDAR`;

    const blob = new Blob([icsContent], { type: 'text/calendar' });
  };

  const formatDateToICS = (date) => {
    return date.toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z';
  };

  if (competitions.length > 0) {
    EVENTS_CONTENT = (
      <ul style={{ padding: 0 }}>
        <Grid container spacing={2}>
          {events?.map((event, eventIndex) => {
            const isMatchSelected = event["__typename"] === "Match" && eventTypes.find(type => type.code === "Match")?.selected;
            const isTrainingSelected = event["__typename"] === "Training" && eventTypes.find(type => type.code === "Training")?.selected;
            
            if (!isMatchSelected && !isTrainingSelected) {
              return null;
            }

            return (
              <Grid container item key={eventIndex} id={`event-${eventIndex}`} alignItems="center" justifyContent="center">
                {isMatchSelected ? (
                  <MatchCard
                    onClick={(id) => matchCardClick(id)}
                    className={classes.matchCard}
                    data={event}
                    updateMatch={() => handleClickUpdateEvent(eventIndex)}
                    removeMatch={() => removeEvent(eventIndex)} 
                    index={eventIndex}
                    withMenu={true}
                  />
                ) : isTrainingSelected ? (
                  <TrainingCard
                    data={event}
                    update={() => handleClickUpdateEvent(eventIndex)}
                    index={eventIndex}
                    withMenu={true}
                    remove={() => removeEvent(eventIndex)} 
                  />
                ) : null}
              </Grid>
            );
          })}
        </Grid>
      </ul>
    );
  }

  // Content
  let CONTENT;

  if(loaded === true) {

    if(dropdownCalendarTypeIndex === 0){
      CONTENT = <div>        
        { (userStored?.groups?.includes("ClubAdmins")|| userStored?.groups?.includes("Coaches")) && teams.length < 1 ? (
            <div className={classes.noTeamsInfo}>
              <NoTeamsCard redirectBtn={userStored?.groups?.includes("ClubAdmins") ? true : false} />
            </div>
          ) : (userStored?.groups?.includes("ClubAdmins") || userStored?.groups?.includes("Coaches")) && competitions.length < 1 ? (
            <div className={classes.noTeamsInfo}>
              <NoCompetitionsCard redirectBtn={true} teamId={teams[teamIndex]?.id} />
            </div>
          ) : EVENTS_CONTENT
        }
      </div>
    }
    else {
      CONTENT = <div style={{width: 1200}}>
        <Scheduler
          events={schedulerEvents}
          updateEvent={(index) => handleClickUpdateEvent(index)}
          removeEvent={(index) => removeEvent(index)}
          addEvent={(data) => onClickModalAdd(data)}
          currentView={calendarViews[dropdownCalendarViewIndex].code}
          onViewChange={handleViewChange}
          selectable={userStored?.groups?.includes("ClubAdmins") || userStored?.groups?.includes("Coaches") ? true : false}
        />
      </div>
    }
  } else {
    CONTENT = <div></div>
  }

  return(
    <div className={classes.container}>
      <CText
        className={classes.title}
        level="h1"
        color="light"
        loading={!loaded}
      >
        {t('calendar.title')} • {season}
      </CText>

      {loaded ?
        <div className="top-container">
          <div className="filters-container">
            <span className={classes.filtersTitle}>
              <FilterIcon className={classes.filtersTitleIcon} /> 
              <CText
                level="p1"
                color="light"
              >
                {t('common.filters')}
              </CText>
            </span>
            
            <Box className={classes.topContainerLeft}>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                {loaded === true && userStored?.groups?.includes("ClubAdmins") && teams.length > 0 ?
                  <TeamsSelector
                    teams={teams}
                    selectedTeamIndex={teamIndex}
                    onSelect={selectTeam}
                  />
                  : null
                }
                {loaded === true && competitions.length > 0 ?
                  <CompetitionsSelector
                    competitions={competitions}
                    selectedCompetitionIndex={competitionIndex}
                    onCompetitionSelect={selectCompetition}
                  />
                  : null
                }

                <DropdownFilter
                  options={calendarTypes}
                  onSelect={handleDropdownCalendarType}
                  selectedIndex={dropdownCalendarTypeIndex}
                  type="icon"
                  sx={{ marginRight: 2 }}
                />

                {calendarTypes[dropdownCalendarTypeIndex].code === "calendar" ?
                  <DropdownFilter
                    options={calendarViews}
                    onSelect={handleDropdownCalendarView}
                    selectedIndex={dropdownCalendarViewIndex}
                    sx={{ marginRight: 0 }}
                  /> : null
                }
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <ChipFilter
                  data={EVENT_TYPES}
                  onChange={onChangeEventTypes}
                  selectedIndex={dropdownCalendarTypeIndex}
                  sx={{ marginRight: 2 }}
                />
              </Box>
            </Box>
          </div>
        </div>
        :
        <Skeleton
          variant="rounded"
          animation="wave"
          width="100%"
          height={100}
          className={classes.filterContainer}
        />
      }

      {CONTENT}

      {editable === true && teams.length > 0 && competitions.length?
        <Fab onClick={() => onClickModalAdd()}>
          <PlusIcon />
        </Fab>
        : null
      }

      <BadRequestSnackBar
        open={openSnack}
        title={snackBarErrorTitle}
        message={snackBarErrorMessage}
        onClose={closeSnackBar}
        autoHideDuration={5000}
        errorElmts={snackBarErrorElmts}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      />

      <CModal
        open={modalOpen.value}
        onClose={() => onCloseModal()}
        content={CONTENT_MODAL}
      >
      </CModal>
    </div>
  )
}
  
export default Calendar;