import React from 'react';
import MuiFab from '@mui/material/Fab';
import './fab.css';

const Fab = ({ onClick, children, className, style, ...props }) => {
  return (
    <MuiFab
      onClick={onClick}
      className={`fab ${className || ''}`.trim()}
      sx={{
        ...style,
      }}
      {...props}
    >
      {children}
    </MuiFab>
  );
};

export default Fab;
