import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from "react-i18next"
import { Auth, API } from 'aws-amplify'
import { sortASC } from "../../utils/sort"
import { getUserGroups } from "../../utils/auth"
import { useSelector } from 'react-redux'

import * as queries from '../../graphql/queries'
import { useLocation, useNavigate } from "react-router-dom";
import CButton from "../../components/Button"
import CModal from "../../components/Modal"
import { CText } from "../../components/Text"
import CSelect from "../../components/Select"
import TextInput from '../../components/TextInput'
import ProfilePicture from "../../components/ProfilePicture/index"
import { getSession } from "../../utils/auth"
import { BadRequestSnackBar } from "../../components/SnackBar"
import NoTeamsCard from "../../components/Cards/NoTeamsCard"
import PlayersTable from '../../components/Table/PlayersTable'
import { ReactComponent as DotsIcon } from '../../assets/icons/dots-vertical.svg';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg';

import { positions, getPositionInfo } from '../../assets/referentials/positions'
import { useStylesModal, useStylesPlayer, useStylesPlayers } from './players.styles.js'
import { getCurrentSeason } from "../../utils/season"
import { updateTeamNameInPath } from "../../utils/urlRedirections";
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK= "#e92163";
const COLOR_BLUE = "#25a5e6";
const COLOR_DARK = '#242834';
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;

export const getPositionOrder = (t) => {
  // Create a lookup object to map position short_label to their respective order
  return positions(t).reduce((order, position, index) => {
    order[position.short_label] = index;
    return order;
  }, {});
};

// Function to compare two players for sorting
const comparePlayers = (playerA, playerB, positionOrder) => {
  // Compare by position first using the order from the positionOrder
  if (positionOrder[playerA.position] < positionOrder[playerB.position]) {
    return -1;
  }
  if (positionOrder[playerA.position] > positionOrder[playerB.position]) {
    return 1;
  }

  // If positions are the same, compare by name
  return playerA.first_name.localeCompare(playerB.first_name);
};

const AddPlayerModal = (props) => {
  const classes = useStylesModal();

  const { t } = useTranslation();

  /********* Handle Email *********/
  const [email, setEmail] = useState("");
  const emailCb = (data) => setEmail(data);

  /********* Handle Position *********/
  const [position, setPosition] = useState(positions(t)[0]);
  const positionCB = (data) => { setPosition(data) }

  /********* Send Invite *********/
  const sendInvite = async () => {
    try {
      let users;
      let errorMessage = ""
      let errorElmts = []

      if(email && position) {
        const userFilter = {
          email: { eq: email },
          type: { eq: 'P' }
        }

        await getSession();
        const listUsersResp = await API.graphql({
          query: queries.listUsers,
          variables: { filter: userFilter },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        users = listUsersResp.data.listUsers.items;

        const currentSeason = await getCurrentSeason();

        let inviteUserInputs = {
          "season": currentSeason,
          "type": "P",
          "position": position.code,
          "user": {}
        };

        let newPlayer = {
          position: getPositionInfo(t, position.code).short_label
        }

        if (users.length > 0) {
          let user = users[0];
          inviteUserInputs.user.id = user.id;

          newPlayer.id = user.id;
          newPlayer.first_name = user.first_name;
          newPlayer.last_name = user.last_name;
          newPlayer.photo_path = user.photo_path;
        } else {
          inviteUserInputs.user.email = email;
        }

        let resp = await fetch(`${process.env.REACT_APP_REST_API_BASE_URL}/invitations/v1/teams/${props.teamId}/invitations`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
          },
          body: JSON.stringify(inviteUserInputs)
        });

        const statusCode = resp.status;

        if (statusCode >= 200 && statusCode < 300) {
          // Get the position order based on the translated positions
          const positionOrder = getPositionOrder(t);

          newPlayer.invite_status = 0;

          // Insert the new player to the invited players list
          props.invitedPlayers.push(newPlayer);
          // Sort the entire array by position, then by name
          props.invitedPlayers.sort((a, b) => comparePlayers(a, b, positionOrder));
        }

        if(statusCode === 409) {
          throw new Error(t('players.errors.1.message'));
        }

        props.modalOpenCallback({ value: false });
      } else {
        errorMessage = t('players.errors.0.message');
        if(!email) { errorElmts.push(t('players.modals.0.inputs.0.label')) };
        if(!position.code) { errorElmts.push(t('players.modals.0.inputs.1.label')) };
        props.errorCallback({ errorMessage: errorMessage, errorElmts: errorElmts });
      }

      return 0;
    } catch (err) {
      props.errorCallback({ errorMessage: err.message || t('players.errors.2.message'), errorElmts: [] });

      props.modalOpenCallback({ value: false })
    }
  }

  return (
    <div className={classes.modalContainer}>
      <div className={classes.form}>
        <CText
          className={classes.formTitle}
          level="h2"
          fontWeight={700}
          color="primary"
        >
          {t('players.modals.0.title')}
        </CText>
        <TextInput className={classes.input} label={`${t('players.modals.0.inputs.0.label')} *`} inputCallback={emailCb} value={email} />
        <CSelect
          label={`${t('players.modals.0.inputs.1.label')} *`}
          data={positions(t)}
          selectCallback={positionCB}
          className={classes.input}
          value={position}
        />
        <div className={classes.formBtnZone}>
          <CButton
            className={classes.formBtn}
            label={t('players.modals.0.buttons.0.label')}
            height={38}
            type="contained"
            loader={true}
            onClick={async () => await sendInvite()}
          />
        </div>
      </div>
    </div>
  );
}

const Player = ({ data, onRemovePlayer }) => {
  const { t } = useTranslation();
  const classes = useStylesPlayer();

  //Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickMore = (event) => { setAnchorEl(event.currentTarget) };
  const handleClose = () => { setAnchorEl(null) };

  const removePlayer = async() => {
    onRemovePlayer(data.id);

    await fetch(`${process.env.REACT_APP_REST_API_BASE_URL}/invitations/v1/invitations/${data.invitation_id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    });
  }

  const deleteInvitation = () => {
    handleClose();
    removePlayer();
  };

  return (
    <li className={classes.dataZone}>
      <Grid container  direction="row" alignItems="center" >
        <Grid container item xs={5}  direction="row" alignItems="center">
          <ProfilePicture className={classes.playerPhoto} src={data.photo_path} />
          <CText className={classes.playerName} level="p2" color="light">{data.first_name} {data.last_name}</CText>
        </Grid>
        <Grid container item xs={1}>
          <CText level="p2" color="light">{data.position}</CText>
        </Grid>
        <Grid container item xs={6} justifyContent="flex-end">
          <div className={`${classes.iconBtn}`}>
            <Tooltip title={t('players.labels.8')}>
              <DotsIcon
                className={`${classes.icon}`}
                onClick={handleClickMore}
              />
            </Tooltip>
          </div>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            sx={{
              '& .MuiPaper-root': {
                minWidth: '10ch',
              },
            }}
            autoFocus={false}
          >
            <MenuItem onClick={() => deleteInvitation()}>
              <span className={classes.listIconZone}>
                <CancelIcon className={classes.listIcon} />
              </span>
              {t('players.labels.11')}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </li>
  );
}

const Players = (props) => {
  const classes = useStylesPlayers();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const userStored = useSelector(state => state?.user);
  const teamId = userStored?.club?.team?.id;

  const [loaded, setLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState({value: false});
  const [players, setPlayers] = useState([]);
  const [invitedPlayers, setInvitedPlayers] = useState([]);
  const [editable, setEditable] = useState(false);
  const [userGroups, setUserGroups] = useState([]);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    updateTeamNameInPath(location.pathname, userStored?.club?.team?.id, navigate);
  }, [location.pathname, userStored?.club?.team?.name, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      await getUserType()
      const userGroups = await getUserGroups()
      setUserGroups(userGroups)

      const teams = await getTeams();

      if (teams.length > 0) {    
        getTeamPlayers();
        getInvitedPlayers();
      }

      setLoaded(true)
    }
  
    fetchData()
  }, [])

  const getUserType = async() => {
    try {
      const groups = userStored?.groups
      if(groups.includes("Coaches") || groups.includes("ClubAdmins")) {
        setEditable(true)
      }
    }
    catch(err) { console.log(err) }
  }

  const getTeams = async() => {
    try {
      const { groups, club } = userStored;
      let teams = [];
    
      if (groups.includes("ClubAdmins")) {
        const clubId = club?.id
  
        const teamsFilter = {
          club_id: { eq: clubId },
          deleted: { eq: false }
        }
  
        let teamsList = await API.graphql({ query: queries.listClubTeams, variables: { filter: teamsFilter }, authMode: 'AMAZON_COGNITO_USER_POOLS'})
        teamsList = teamsList.data.listTeams.items;
  
        const teamsListOrdered = teamsList.sort((a, b) => {
          const numberA = parseInt(a.name.replace(/\D/g, ''), 10) || 0;
          const numberB = parseInt(b.name.replace(/\D/g, ''), 10) || 0;
          return numberA - numberB;
        });
  
        teams = teamsListOrdered;
      } else {
        const team = club?.team
        teams.push(team)
      }
  
      setTeams(teams)
  
      return teams;
    }
    catch(err) { console.log(err) }
  }

  const getTeamPlayers = async(teams) => {
    try {
      let [goalkeepersArray, defendersArray, midfieldersArray, strickersArray, players] = [[], [], [], [], []];
      const { groups } = userStored;
      const currentSeason = await getCurrentSeason();

      const userFilter = {
        invite_status: { eq: 1 },
        user_type: { eq: "P" },
        end_date: { attributeExists: false },
        season: { eq: currentSeason }
      }
      const matchFilter = {
        season: { eq: currentSeason },
        start_at: { ge: 0, le: 90 }
      }
      const goalFilter = {
        season: { eq: currentSeason },
        team_id: { eq: teamId }
      }
      const assistFilter = {
        season: { eq: currentSeason },
        team_id: { eq: teamId }
      }
      const cardFilter = {
        season: { eq: currentSeason },
        team_id: { eq: teamId }
      }

      let teamPlayers = await API.graphql({
        query: queries.getTeamPlayersWithMatchesInfo,
        variables: {
          id: teamId,
          userFilter: userFilter,
          matchFilter: matchFilter,
          goalFilter: goalFilter,
          assistFilter: assistFilter,
          cardFilter: cardFilter
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })

      teamPlayers = teamPlayers.data.getTeam.users.items;

      if(teamPlayers.length > 0) {
        let teamPlayersOrdered = teamPlayers.sort(sortASC("first_name"))

        await teamPlayersOrdered.map( (player, index) => {
          switch (player.position) {
            case 'GK':
              goalkeepersArray.push(player)
              break
            case 'DF':
              defendersArray.push(player)
              break
            case 'MF':
              midfieldersArray.push(player)
              break
            case 'ST':
              strickersArray.push(player)
              break
          }
        })

        teamPlayersOrdered = [...goalkeepersArray, ...defendersArray, ...midfieldersArray, ...strickersArray]

        await teamPlayersOrdered.map((player, index) => {
          let position
          switch (player.position) {
            case 'GK':
              position = t('positions.0.shortLabel')
              break
            case 'DF':
              position = t('positions.1.shortLabel')
              break
            case 'MF':
              position = t('positions.2.shortLabel')
              break
            case 'ST':
              position = t('positions.3.shortLabel')
              break
          }

          const yellowCards = player.user.cards.items.filter(card => card.type === "Y");
          const redCards = player.user.cards.items.filter(card => card.type === "R");

          const yellowCardCount = yellowCards.length;
          const redCardCount = redCards.length;

          players.push({
            id: player?.user?.id,
            first_name: player?.user?.first_name,
            last_name: player?.user?.last_name,
            photo_path: player?.user?.photo_path,
            position: position,
            matches: player?.user?.matches?.items?.length,
            goals: player?.user?.goals?.items?.length,
            assists: player?.user?.assists?.items?.length,
            yellow_cards: yellowCardCount,
            red_cards: redCardCount,
            invitation_id: player?.id
          });
        })
      }

      setPlayers(players);
    }
    
    catch(err) { console.log(err) }
  }

  const getInvitedPlayers = async() => {
    try {
      const { groups } = userStored;
      const currentSeason = await getCurrentSeason();

      const userFilter = {
        or: [
          { invite_status: { eq: 0 }}
        ],
        user_type: { eq: "P" },
        season: { eq: currentSeason },
        end_date: { attributeExists: false }
      };

      let invitedPlayers = await API.graphql({
        query: queries.getTeamPlayers,
        variables: {
          id: teamId,
          userFilter: userFilter
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      invitedPlayers = invitedPlayers.data.getTeam.users.items;
      let invitedPlayersOrdered = invitedPlayers.sort(sortASC("first_name"));
      let [goalkeepersArray, defendersArray, midfieldersArray, strickersArray, players] = [[], [], [], [], []]

      await invitedPlayersOrdered.map( (player, index) => {
        switch (player.position) {
          case 'GK':
            goalkeepersArray.push(player)
            break
          case 'DF':
            defendersArray.push(player)
            break
          case 'MF':
            midfieldersArray.push(player)
            break
          case 'ST':
            strickersArray.push(player)
            break
        }
      })

      invitedPlayersOrdered = [...goalkeepersArray, ...defendersArray, ...midfieldersArray, ...strickersArray]

      await invitedPlayersOrdered.map((player) => {
        let position

        switch (player.position) {
          case 'GK':
            position = t('positions.0.shortLabel')
            break
          case 'DF':
            position = t('positions.1.shortLabel')
            break
          case 'MF':
            position = t('positions.2.shortLabel')
            break
          case 'ST':
            position = t('positions.3.shortLabel')
            break
        }

        players.push({
          id: player?.user?.id,
          first_name: player?.user?.first_name,
          last_name: player?.user?.last_name,
          photo_path: player?.user?.photo_path,
          position: position,
          invitation_id: player?.id
        })
      })
      setInvitedPlayers(players)
    }
    
    catch(err) { console.log(err) }
  }
  
  const onClickModalAddPlayer = () => {
    setModalOpen({type: "addPlayer", value: true});
  }

  /********* Handle Error *********/
  const [openSnack, setOpenSnack] = useState(false)
  const [snackBarErrorTitle, setSnackBarErrorTitle] = useState("")
  const [snackBarErrorMessage, setSnackBarErrorMessage] = useState("")
  const [snackBarErrorElmts, setSnackBarErrorElmts] = useState("")

  const errorCB = (data) => {
    setOpenSnack(true)
    setSnackBarErrorTitle(data.errorTitle)
    setSnackBarErrorMessage(data.errorMessage)
    setSnackBarErrorElmts(data.errorElmts)
  }

  const closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
    setSnackBarErrorMessage("");
  }

  // Content Modal
  let contentModal;
  if(modalOpen.type == "addPlayer") {
    contentModal =
    <AddPlayerModal
      modalOpenCallback={(data) => setModalOpen(data)}
      errorCallback={errorCB}
      teamId={teamId}
      invitedPlayers={invitedPlayers}
    />
  }

  const handleRemovePlayer = (playerId) => {
    const updatedInvitedPlayers = invitedPlayers.filter(player => player.id !== playerId);
    setInvitedPlayers(updatedInvitedPlayers);
  };

  return(
    <div className={classes.container}>
      <CText level="h1" color="light">{t('players.title')}</CText>
      <div className={classes.topContainer}>
        {editable ?
          <div className={classes.topButtonsContainer}>
            <CButton
              className={classes.addButton}
              label={t('button.add')}
              type="contained"
              color={COLOR_PRIMARY}
              size={14}
              minWidth={BUTTON_WIDTH}
              maxWidth={BUTTON_WIDTH}
              onClick={() => onClickModalAddPlayer()}
            />
          </div>
          : null
        }
      </div>

      {loaded === true && userStored?.groups?.includes("ClubAdmins") && teams.length < 1 ?
        <div className={classes.noTeamsInfo}>
          <NoTeamsCard redirectBtn={true} />
        </div>
        :
        null
      }

      {loaded === true && teams.length > 0 ?
        <div style={{backgroundColor: ZONE_BACKGROUND_COLOR, borderRadius: 12, paddingBottom: 25 }}>
          <PlayersTable data={players} type={userGroups[0]} />
        </div>
        :
        null
      }

      {userGroups.includes("Coaches") || userGroups.includes("ClubAdmins") ?
        <div className={classes.inviteList}>
          <CText className={classes.inviteListTitle} level="h3" color="primary">{t('players.labels.7')}</CText>
          <ul>
            <Grid container spacing={1}>
              {invitedPlayers.map((player, playerIdx) => (
                <Grid container item key={playerIdx} alignItems="center" justifyContent="center">
                  <Player data={player} onRemovePlayer={handleRemovePlayer} />
                </Grid>
              ))}
            </Grid>
          </ul>
        </div> : null
      }

      <BadRequestSnackBar
        open={openSnack}
        title={snackBarErrorTitle}
        message={snackBarErrorMessage}
        onClose={closeSnackBar}
        autoHideDuration={5000}
        errorElmts={snackBarErrorElmts}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      />

      <CModal
        open={modalOpen.value}
        onClose={() => setModalOpen({value: false})}
        content={contentModal}
      >
      </CModal>
    </div>
  )
}
  
export default Players;