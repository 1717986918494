import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { styled } from '@mui/material/styles';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { useStylesMenu } from './Menu.styles.js';
import { ReactComponent as RightChevronIcon } from '../../assets/icons/right-chevron.svg';
import { CText } from "../Text.js";
import { menuList } from "./list.js";
import { API } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import Tooltip from '@mui/material/Tooltip';
import { ClubPhoto } from "../../components/Photos/ClubPhoto";
import CButton from "../../components/Button";
import './menu.css';

const COLOR_LIGHT = "#f4f4f4";
const MENU_BACKGROUND_COLOR = "rgba(28, 30, 33, 1)";
const MENU_LABEL_COLOR = "rgba(242, 242, 242, 1)";
const MENU_LABEL_ACTIVE_COLOR = "rgba(69, 214, 159, 1)";
const MENU_LABEL_ACTIVE_BACKGROUND_COLOR = "rgba(69, 214, 159, 0.07)";
const MENU_WIDTH = 250;
const MENU_LABEL_MARGIN = 10;
const MENU_LABEL_PADDING_HORIZONTAL = 15;
const MENU_LABEL_PADDING_VERTICAL = 5;
const COLOR_PRIMARY = "#45d69f";
const COLOR_PINK = "#e92163";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	backgroundColor: 'transparent',
  '&:not(:last-child)': {
    border: 0,
  },
  '&:before': {
		border: 0,
    display: 'none'
  }
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary {...props} />
))(({ theme, ...props }) => {
  return {
    minHeight: 0,
    borderRadius: 8,
    height: 35,
    paddingLeft: MENU_LABEL_PADDING_HORIZONTAL,
    paddingRight: MENU_LABEL_PADDING_HORIZONTAL,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    "&:hover, &:visited, &:link, &:active": {
      backgroundColor: MENU_LABEL_ACTIVE_BACKGROUND_COLOR,
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
      padding: MENU_LABEL_PADDING_VERTICAL,
      "&$expanded": {
        padding: MENU_LABEL_PADDING_VERTICAL,
      },
    },
  };
});

const AccordionSummary2 = styled((props) => (
  <MuiAccordionSummary {...props} />
))(({ theme, ...props }) => {
  return {
    minHeight: 0,
    borderRadius: 8,
    height: 40,
    paddingLeft: MENU_LABEL_PADDING_HORIZONTAL,
    paddingRight: MENU_LABEL_PADDING_HORIZONTAL,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    "&:hover, &:visited, &:link, &:active": {
      backgroundColor: MENU_LABEL_ACTIVE_BACKGROUND_COLOR,
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
      padding: MENU_LABEL_PADDING_VERTICAL,
      "&$expanded": {
        padding: MENU_LABEL_PADDING_VERTICAL,
      },
    },
  };
});


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
	paddingBottom: 0,
	border: 0,
	margin: 0,
	marginTop: -14
}));

export const SideMenu = () => {
  const classes = useStylesMenu();
	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const user = useSelector(state => state?.user);
	const userId = user?.info?.id;
	const teamId = user?.club?.team?.id;

	const [loading, setLoading] = useState(true);
	const [menu, setMenu] = useState([]);
  const [team, setTeam] = useState({});

  useEffect(() => {  
    const fetchData = async () => {
      loadMenu();
    };

    fetchData();
  }, [userId, teamId]);

  const documentsRequestedCount = useSelector((state) => state?.documents?.requested_count);
	const invitationCount = useSelector((state) => state?.invitations?.count);
  const firstConversation = useSelector((state) => state?.conversations?.conversations?.[0]);
  const conversationCount = firstConversation ? firstConversation.count : 0;  

	const loadMenu = async() => {
		let menu = await menuList(t, user);

    if (teamId) {
      await getTeam(teamId);
    }

		setMenu(menu);
		setLoading(false);
	}

	const handleChange = (menuIndex) => {
		let newMenu = [...menu]
  	newMenu[menuIndex].open = !menu[menuIndex].open
		setMenu(newMenu)
	};

  const getTeam = async(teamId) => {
    const getTeamResp = await API.graphql({
      query: queries.getTeam,
      variables: { 
        id: teamId
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });

    setTeam(getTeamResp.data.getTeam);
    return getTeamResp.data.getTeam;
  }

  const changeTeam = () => {
    const queryParams = location.search;
    if (user?.groups?.includes("ClubAdmins")) {
      navigate(`/clubs/${user.club.id}/teams${queryParams}`, { state: { prevUrl: window.location.pathname + queryParams } });
    } else if (user?.groups?.includes("Players") || user?.groups?.includes("Coaches")) {
      navigate(`/users/${user.info.id}/teams${queryParams}`, { state: { prevUrl: window.location.pathname + queryParams } });
    }
  };

	const handleNavigate = async(path, item) => {
    let teamName = 'no_team';
    const teamId = user?.club?.team?.id;
    if(teamId) {
      const team = await getTeam(teamId);
      teamName = team?.name;
    }

    const currentPath = path.replace(':teamName', encodeURIComponent(teamName));

    const url = new URL(currentPath, window.location.origin);

    if (item.id === "calendar_and_results") {
      url.searchParams.set('team', teamName);
    }

    // En séparant le chemin et les paramètres de recherche
    const finalPath = `${url.pathname}${url.search}`;
    navigate(finalPath);
    window.scrollTo(0, 0);
	};

	const isPathActive = (currentPath, menuPath) => {
    const segmentsMenu = menuPath?.split('/');
    const segmentsCurrent = currentPath?.split('/');
    
    if (segmentsMenu?.length !== segmentsCurrent?.length) return false;

    for (let i = 0; i < segmentsMenu?.length; i++) {
        if (segmentsMenu[i] === ':teamName') continue;
        if (segmentsMenu[i] !== segmentsCurrent[i]) return false;
    }

    return true;
	}

  const renderSubMenu = (submenu) => {
    return submenu?.map((subItem, submenuIndex) => {
        const isActiveSub = isPathActive(location.pathname, subItem.link_to);
        return (
            <div
                onClick={() => handleNavigate(subItem.link_to, subItem)}
                key={subItem.title}
                className={`menu-title-zone menu-subtitle-zone ${isActiveSub ? "menu-title-zone-focus" : ""}`}
            >
                <div
                  className={isActiveSub ? "menu-icon-focus" : "menu-icon"}
                >
                    {subItem.icon}
                </div>
                <CText fontSize={15} fontWeight={500} label={subItem.title} />
            </div>
        );
    });
  }

  const renderMenu = (menuItem, menuIndex) => {
    const isActiveMenu = isPathActive(location.pathname, menuItem.link_to);

    if (menuItem.id === "team" && team.name) {
      return (
        <div key={menuItem.id}>
          <Tooltip
            disableInteractive
            title={`${team?.club?.names?.[0] ? JSON.parse(team.club.names[0]).name : ''} • ${team?.name || ''}`}
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -20],
                  },
                },
                {
                  name: 'zIndex',
                  enabled: true,
                  phase: 'afterWrite',
                  fn: ({ state }) => {
                    state.styles.popper.zIndex = '100000';
                  },
                },
              ],
            }}
          >
            <span className="menu-team" onClick={changeTeam}>
              <div className="menu-team-logo-zone">
                <ClubPhoto className="menu-team-logo" src={team?.club?.logo} />
              </div>
              <CText
                className="label"
                level="p2"
                fontWeight={700}
                color="light"
              >
                {team?.name?.length > 7 ? `${team.name.slice(0, 5)}...` : team.name}
              </CText>
              <RightChevronIcon
                className="menu-chevron-icon"
              />
            </span>
          </Tooltip>
          <div>
            {renderSubMenu(menuItem.submenu)}
          </div>
        </div>
      );
    } else if(menuItem.id === "team" && !team?.name) {
      return (
        <div key={menuItem.id} className="menu-btn-wrapper">
          <CButton
            className="menu-btn"
            label={t('menu.1.btns.0.label')}
            type="contained"
            color={COLOR_PRIMARY}
            size={14}
            height={32}
            onClick={changeTeam}
          />
        </div>
      );
    }


    if (menuItem?.submenu?.length < 1) {
      return (
          <div
            onClick={() => handleNavigate(menuItem.link_to, menuItem)}
            className={`menu-title-zone ${isActiveMenu ? "menu-title-zone-focus" : ""}`}
          >
            <div className={isActiveMenu ? "menu-icon-focus" : "menu-icon"}>
              {menuItem.icon}
            </div>
            <CText fontSize={15} fontWeight={500} style={{ position: 'relative' }}>
              {menuItem.title}
              {menuItem.id === "invitations" && invitationCount > 0 && (
                <span className="menu-badge-zone">
                  <span className="menu-badge">
                    {invitationCount > 9 ? (
                      <span className="menu-badge-content">
                        <span>9</span>
                        <span className="menu-badge-plus">+</span>
                      </span>
                    ) : (
                      <span>{invitationCount}</span>
                    )}
                  </span>
                </span>
              )}

              {menuItem.id === "documents" && documentsRequestedCount > 0 && (
                <span className="menu-badge-zone">
                  <span className="menu-badge">
                    {documentsRequestedCount > 9 ? (
                      <span className="menu-badge-content">
                        <span>9</span>
                        <span className="menu-badge-plus">+</span>
                      </span>
                    ) : (
                      <span>{documentsRequestedCount}</span>
                    )}
                  </span>
                </span>
              )}

              {menuItem.id === "messages" && conversationCount > 0 && (
                <span lassName="menu-badge-zone">
                  <span className="menu-badge">
                    {conversationCount > 9 ? (
                      <span className="menu-badge-content">
                        <span>9</span>
                        <span className="menu-badge-plus">+</span>
                      </span>
                    ) : (
                      <span>{conversationCount}</span>
                    )}
                  </span>
                </span>
              )}
            </CText>
          </div>
      );
    } else {
        return (
          <Accordion expanded={menuItem.open} square={true} onChange={() => handleChange(menuIndex)} style={{marginLeft: 10, marginRight: 10}}>
            <AccordionSummary expandIcon={<RightChevronIcon className={classes.rightIcon} />}>
              <div className={isActiveMenu ? classes.menuIconFocus : classes.menuIcon}>
                  {menuItem.icon}
              </div>
              <CText fontSize={15} fontWeight={500} color="light" label={menuItem.title} />
            </AccordionSummary>
            <AccordionDetails>
              {renderSubMenu(menuItem.submenu)}
            </AccordionDetails>
          </Accordion>
        );
    }
  }

  return (
    <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
            paper: classes.drawerZone
        }}
    >
        <Toolbar />
        {loading ? null : (
          <div className={classes.drawerContainer}>
            <List>
              {menu?.map((menuItem, menuIndex) => (
                <React.Fragment key={menuIndex}>
                  {renderMenu(menuItem, menuIndex)}
                  <Divider className={classes.divider} />
                </React.Fragment>
              ))}
            </List>
          </div>
        )}
    </Drawer>
  );
}