import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import i18next from 'i18next'
import { useTranslation } from "react-i18next"
import moment from 'moment';
import { formatTime, getTimeFormat, getDateFormat,  } from '../utils/dates';
import './DatePicker.css'
import utc from 'dayjs/plugin/utc';



import { DateField } from '@mui/x-date-pickers/DateField';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import 'dayjs/locale/de';

const COLOR_LIGHT = "#f4f4f4";
const COLOR_PRIMARY = '#45d69f';
const LABEL_COLOR = '#101116';
const TEXTFIELD_COLOR = '#242834';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    borderRadius: 8,
    backgroundColor: TEXTFIELD_COLOR
  },
  root: {
    color: COLOR_LIGHT,
    fontSize: 16,
    "&$focusedLabel": {
      color: COLOR_PRIMARY
    },
    "&$erroredLabel": {
      color: "rgb(208,48,63)"
    }
  },
  inputRoot: {
    color: COLOR_LIGHT,
    borderRadius: 8,
    "&&&:before": {
      borderBottom: "none"
    },
    "&:after": {
      borderRadius: 8,
      borderBottom: `2px solid #45d69f`
    }
  },
  focusedLabel: { color: "#45d69f" },
  pickerRoot: {
    color: COLOR_LIGHT
  }
}));
    
const CDatePicker = (props) => {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const dateFormat = getDateFormat(i18n.language);

  const [locale, setLocale] = React.useState(i18n.language);
  const [inputValue, setInputValue] = useState("");

  const handleChange = (newValue) => {
    setInputValue(newValue);
    const formatedDate = newValue?.format("YYYY-MM-DD");
    props.inputCallback(formatedDate);
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={locale}
      localeText={dateFormat}
    >
      <DateField
        label={props.label}
        variant="filled"
        size="small"
        defaultValue={dayjs(props.defaultValue)}
        onChange={handleChange}
        className={[classes.input, props.className].join(" ")}
        error={false}
        sx={{
          width: '100%',
          borderRadius: '8px',
          backgroundColor: TEXTFIELD_COLOR,
          '& .MuiInputBase-root': {
            borderRadius: '8px',
            color: COLOR_LIGHT,
            backgroundColor: TEXTFIELD_COLOR,
            borderBottom: 'none !important',
            '&:before': {
              borderBottom: 'none !important',
            },
            '&:after': {
              borderBottom: `2px solid ${COLOR_PRIMARY}`,
            },
            '&:hover': {
              backgroundColor: TEXTFIELD_COLOR,
              filter: 'brightness(90%)',
              borderBottom: 'none !important',
            },
            '&.Mui-focused': {
              backgroundColor: TEXTFIELD_COLOR,
              color: COLOR_LIGHT
            },
          },
          '& .MuiInputLabel-root': {
            color: COLOR_LIGHT,
            fontSize: '16px',
            transition: 'transform 0.2s ease, font-size 0.2s ease',
            '&.Mui-focused': {
              color: COLOR_PRIMARY,
            },
            '&.MuiFormLabel-filled': {
              top: -5,
            },
          },
          '& .Mui-focused': {
            color: COLOR_PRIMARY,
          },
          '& .Mui-error': {
            color: 'inherit',
            '&:before': {
              borderBottom: 'none !important',
            },
            '&:after': {
              borderBottom: 'none !important'
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};

const CTimePicker = (props) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const timeFormat = getTimeFormat(i18n.language);

  const is12HourFormat = timeFormat.includes('A') || timeFormat.includes('a');

  const [inputValue, setInputValue] = useState(
    props.defaultValue ? formatTime(new Date(props.defaultValue), timeFormat) : ''
  );
  const [previousValue, setPreviousValue] = useState(inputValue);

  const formatTimeInput = (value) => {
    if (!value) return '';

    const numbersOnly = value.replace(/[^0-9]/g, '');
    let formattedTime = '';

    if (is12HourFormat) {
      if (numbersOnly.length >= 2) {
        formattedTime = `${numbersOnly.slice(0, 2)}:${numbersOnly.slice(2, 4)}`;

        if (value.length === 2 && previousValue.length !== 1) {
          formattedTime = `${numbersOnly.slice(0, 2)}${numbersOnly.slice(2, 4)}`;
        }
        
        if (formattedTime.length === 5 && previousValue.length !== 8) {
          if (value[5] === 'A' || value[4] === 'a') {
            formattedTime += ' AM';
          } else {
            formattedTime += ' PM';
          }
        }

      } else {
        formattedTime = numbersOnly;
      }
    } else {
      if (numbersOnly.length >= 2) {
        formattedTime = `${numbersOnly.slice(0, 2)}:${numbersOnly.slice(2, 4)}`;
      } else {
        formattedTime = numbersOnly;
      }
    }

    return formattedTime;
  };

  const handleInputChange = (event) => {
    const { value } = event.target;

    //const formattedValue = value.length >= inputValue.length ? formatTimeInput(value) : value;
    const formattedValue = formatTimeInput(value);

    setInputValue(formattedValue);
    setPreviousValue(formattedValue);

    // Appeler inputCallback uniquement si l'entrée n'est pas vide
    const momentTime = moment(formattedValue, timeFormat, true);
    props.inputCallback(momentTime);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TextField
        label={props.label}
        value={inputValue}
        onChange={handleInputChange}
        variant="filled"
        size="small"
        className={[classes.input, props.className].join(' ')}
        InputLabelProps={{
          shrink: true,
          classes: {
            root: classes.root,
            focused: classes.focusedLabel,
            error: classes.erroredLabel
          }
        }}
        InputProps={{
          classes: {
            root: classes.inputRoot
          }
        }}
        placeholder={timeFormat}
      />
    </LocalizationProvider>
  );
};

export { CDatePicker, CTimePicker };