import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { API } from 'aws-amplify';
import { useSelector } from 'react-redux';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import CButton from "../../components/Button";
import { CText } from "../../components/Text";
import MatchCard from '../../components/Cards/MatchCard';
import LineUp from '../../components/LineUp/LineUp';
import NoGame from "../../components/Cards/NoGame";
import { getCurrentSeason } from "../../utils/season";
import { useParams } from 'react-router-dom';
import { ReactComponent as ChangesIcon } from '../../assets/icons/changes.svg';
import './pregame.css';

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK = "#e92163";
const COLOR_BLUE = "#25a5e6";
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;
const PLAYER_SUBSITUTES_CARD_HEIGHT = 120;

class Pipeline {
  constructor() {
    this.queue = [];
    this.isProcessing = false;
  }

  async add(task) {
    return new Promise((resolve, reject) => {
      this.queue.push({ task, resolve, reject });
      this.processQueue();
    });
  }

  async processQueue() {
    if (this.isProcessing || this.queue.length === 0) {
      return;
    }

    this.isProcessing = true;

    const { task, resolve, reject } = this.queue.shift();

    try {
      const result = await task();
      resolve(result);
    } catch (error) {
      reject(error);
    } finally {
      this.isProcessing = false;
      this.processQueue();
    }
  }
}

const PreGame = (props) => {
  const { t } = useTranslation()
  const userStored = useSelector(state => state?.user);

  const [editable, setEditable] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [game, setGame] = useState({});
  const [currentSeason, setCurrentSeason] = useState("");
  const [isSavingData, setIsSavingData] = useState(false);
  const [lineUp, setLineUp] = useState({});
  const [captain, setCaptain] = useState("");
  const [hasChanged, setHasChanged] = useState(false);

  const { matchId } = useParams();

  useEffect(() => {  
    const fetchData = async () => {
      await getUserType();
      const currentSeason = await getCurrentSeason();
      setCurrentSeason(currentSeason);
      const game = await getGame();
      const currentFormation = await getFormation(game);
      await getLineUp(game, currentFormation, currentSeason);
      setLoaded(true);
    }
  
    fetchData();
  }, []);

  const getUserType = async() => {
    try {
      const groups = userStored?.groups;
      if(groups.includes("Coaches")) {
        setEditable(true);
      }
    }
    catch(err) { console.log(err) }
  }

  const getGame = async() => {
    try {
      let getMatchResp = await API.graphql({
        query: queries.getMatch,
        variables: { id: matchId },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      getMatchResp = getMatchResp.data.getMatch;

      setGame(getMatchResp);

      return getMatchResp;
    }
    catch(err) {
      //console.log(err)
    }
  }

  const getFormation = async (game) => {
    let currentFormation;
    if(game?.home_team?.id) {
      game?.home_team_formation ? currentFormation = game?.home_team_formation : currentFormation =  "4-3-3"
    } else {
      game?.away_team_formation ? currentFormation = game?.away_team_formation : currentFormation =  "4-3-3"
    }

    return currentFormation
  }

  const getLineUp = async (game, currentFormation, currentSeason) => {
    try {
      const matchPlayerFilter = {
        match_id: { eq: game.id }
      }
  
      const team = userStored?.club.team;
      const userTeamFilter = {
        season: { eq: currentSeason },
        team_id: { eq: team.id },
        user_type: { eq: "P" },
        end_date: { attributeExists: false },
        invite_status: { eq: 1 }
      }
  
      let matchPlayers = await API.graphql({
        query: queries.listMatchPlayers,
        variables: { filter: matchPlayerFilter, userTeamFilter: userTeamFilter },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
  
      const captainPlayer = matchPlayers.data.listMatchPlayers.items.find(player => player.captain === true);
      const captainId = captainPlayer ? captainPlayer.user.id : null;

      if (captainPlayer) {
        setCaptain(captainPlayer.user.id);
      } else {
        setCaptain(null);
      }
  
      matchPlayers = matchPlayers.data.listMatchPlayers.items.map(player => {
        const adaptedPlayer = {
          position_number: player.position,
          captain: player.captain,
          player: {
            id: player.user.id,
            first_name: player.user.first_name,
            last_name: player.user.last_name,
            photo_path: player.user.photo_path,
            position: player.user.teams.items[0].position,
            match_player_id: player.id
          }
        };
  
        if (adaptedPlayer.position_number < 12) {
          return { ...adaptedPlayer, line_up: true };
        } else if (adaptedPlayer.position_number === 12) {
          return { ...adaptedPlayer, bench_players: true };
        } else {
          return adaptedPlayer;
        }
      });
  
      const lineUpInitial = [
        { position: 1, player: {} },
        { position: 2, player: {} },
        { position: 3, player: {} },
        { position: 4, player: {} },
        { position: 5, player: {} },
        { position: 6, player: {} },
        { position: 7, player: {} },
        { position: 8, player: {} },
        { position: 9, player: {} },
        { position: 10, player: {} },
        { position: 11, player: {} }
      ];
  
      const gameLineUp = lineUpInitial.map((player) => {
        const matchingPlayer = matchPlayers.find((p) => p.position_number === player.position);
  
        if (matchingPlayer) {
          return {
            ...player,
            player: {
              id: matchingPlayer.player.id,
              first_name: matchingPlayer.player.first_name,
              last_name: matchingPlayer.player.last_name,
              photo_path: matchingPlayer.player.photo_path,
              position: matchingPlayer.player.position,
              match_player_id: matchingPlayer.player.match_player_id,
            }
          };
        } else {
          return player;
        }
      });
  
      const benchPlayers = matchPlayers.filter(player => player.bench_players);
      setLineUp({ formation: currentFormation, line_up: gameLineUp, bench_players: benchPlayers, captain: captainId });
    } catch (err) {
      //console.log(err);
    }
  };
  
  /********* Handle Lineup *********/
  const useStyles = makeStyles((theme) => ({
    container: {
      height: '100%',
      flexGrow: 1,
      [theme.breakpoints.up('xs')]: {
        paddingLeft: 0,
        paddingRight: 0
      },
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 10,
        paddingRight: 10
      },
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 30,
        paddingRight: 30
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: 45,
        paddingRight: 45
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 80,
        paddingRight: 80
      }
    },
    topButtonZone: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '100%',
      boxSizing: 'border-box',
      marginBottom: 20,
      gap: 8
    },
    titleZone: {
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'start',
      boxSizing: 'border-box',
      width: '100%'
    },
    title: {
      marginBottom: 15
    },
    contentZone: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    matchCard: {
      marginBottom: 25
    },
    noTeamsInfo: {
      width: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'center'
    }
  }));
  const classes = useStyles();

  const updateFormationPipeline = new Pipeline();

  const addPlayer = async (data) => {
    await updateFormationPipeline.add(async () => {
      setIsSavingData(true);
  
      try {
        const newMatchPlayer = {
          user_id: data.player.id,
          match_id: game.id,
          matchPlayersId: game.id,
          position: data.pitch_position,
          season: currentSeason
        };
  
        await API.graphql({
          query: mutations.createMatchPlayer,
          variables: { input: newMatchPlayer },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
  
      } catch (error) {
        //console.error('Error adding player:', error);
        //throw error;
      } finally {
        setIsSavingData(false);
      }
    });
  };
  
  const removePlayer = async (data) => {
    await updateFormationPipeline.add(async () => {
      setIsSavingData(true);
  
      try {
        const matchPlayerFilter = {
          match_id: { eq: game.id },
          user_id: { eq: data.id }
        };
  
        let matchPlayer = await API.graphql({
          query: queries.listMatchPlayers,
          variables: { filter: matchPlayerFilter },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
  
        matchPlayer = matchPlayer.data.listMatchPlayers.items[0];
  
        if (!matchPlayer) {
          //console.warn(`Aucun MatchPlayer trouvé pour le joueur ${data.id}`);
          return;
        }
  
        const deleteInput = { id: matchPlayer.id };
  
        await API.graphql({
          query: mutations.deleteMatchPlayer,
          variables: { input: deleteInput },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
      } catch (error) {
        //console.error('Erreur lors de la suppression du joueur :', error);
        //throw error;
      } finally {
        setIsSavingData(false);
      }
    });
  };
  
  const addCaptain = async (data) => {
    await updateFormationPipeline.add(async () => {
      try {
        setIsSavingData(true);
  
        const oldCaptainMatchFilter = {
          match_id: { eq: game.id },
          captain: { eq: true }
        };
  
        const newCaptainMatchFilter = {
          match_id: { eq: game.id },
          user_id: { eq: data.player_id }
        };
  
        const [oldCaptainResponse, newCaptainResponse] = await Promise.all([
          API.graphql({
            query: queries.listMatchPlayers,
            variables: { filter: oldCaptainMatchFilter },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          }),
          API.graphql({
            query: queries.listMatchPlayers,
            variables: { filter: newCaptainMatchFilter },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          })
        ]);
  
        const oldCaptainMatch = oldCaptainResponse.data.listMatchPlayers.items[0];
        const newCaptainMatch = newCaptainResponse.data.listMatchPlayers.items[0];
  
        if (oldCaptainMatch && data.is_captain) {
          await API.graphql({
            query: mutations.updateMatchPlayer,
            variables: {
              input: {
                id: oldCaptainMatch.id,
                captain: false
              }
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          });
        }
  
        if (newCaptainMatch) {
          await API.graphql({
            query: mutations.updateMatchPlayer,
            variables: {
              input: {
                id: newCaptainMatch.id,
                captain: data.is_captain
              }
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          });
        } else {
          //console.warn("Nouveau capitaine introuvable");
        }
      } catch (error) {
        //console.error("Erreur lors de la mise à jour du capitaine :", error);
        //throw error;
      } finally {
        setIsSavingData(false);
      }
    });
  };

  const updateFormation = async (lineUp) => {
    let matchInput = {
      id: game.id
    };

    if (game?.home_team?.id) {
      if (game?.home_team_formation !== lineUp.formation) {
        matchInput.home_team_formation = lineUp.formation;
      }
    } else {
      if (game?.away_team_formation !== lineUp.formation) {
        matchInput.away_team_formation = lineUp.formation;
      }
    }

    if (updateFormation) {
      await API.graphql({
        query: mutations.updateMatch,
        variables: { input: matchInput },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
    }
  };

  const onChangeLineup = (lineUp) => {
    setLineUp(lineUp);
  }

  const onClickSubmission = async () => {
    try {
      const players = [...lineUp.line_up, ...lineUp.bench_players];

      const updatePromises = players.map((player) => {
        if(player?.player?.match_player_id) {
          const updateInput = {
            id: player.player.match_player_id,
            invitation_status: "INVITED"
          };
  
          return API.graphql({
            query: mutations.updateMatchPlayer,
            variables: { input: updateInput },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          });
        }
        return null;
      });
  
      await Promise.all(updatePromises);
    } catch (error) {
      //console.error('Error during save:', error);
    }
  };

  // Content
  let CONTENT;
  if(loaded === true) {
    if(game) {
      CONTENT =
      <div className={classes.contentZone}>
        <div className={classes.titleZone}>
          <CText className={classes.title} level="h1" color="light">{t('pre_game.title')}</CText>
        </div>
        {editable ?
          <div className={classes.topButtonZone}>
            {isSavingData ?
              <div className="saving-info">
                <ChangesIcon className="saving-icon rotate-icon" />
                <CText className={classes.title} level="p1" color="light">{t('pre_game.labels.0.label')}</CText>
              </div>
              :
              <>
                <CButton
                  label={game.team_sheet.status === "SUBMITTED" ? t('pre_game.buttons.3') : t('pre_game.buttons.2')}
                  type="contained"
                  color={COLOR_PRIMARY}
                  height={36}
                  size={14}
                  minWidth={BUTTON_WIDTH}
                  onClick={() => onClickSubmission()}
                  loader={true}
                  disabled={game.team_sheet.status === "SUBMITTED"}
                />
              </>
            }
          </div>
          : null
        }
        <MatchCard data={game} className={classes.matchCard} />
        <LineUp
          currentSeason={currentSeason}
          gameDate={game.date}
          lineUp={lineUp}
          onAddPlayer={addPlayer}
          onRemovePlayer={removePlayer}
          onChangeCaptain={addCaptain}
          onChange={onChangeLineup}
          editable={editable}
        />
      </div>
    } else {
      CONTENT =
      <div className={classes.noTeamsInfo}>
        <NoGame type="game" />
      </div>
    }
  } else {
    CONTENT = <div></div>
  }

  return (
    <div className={classes.container}>
      {CONTENT}
    </div>
  )
}

export default PreGame;