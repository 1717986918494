import React, { useState, useEffect, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from "react-i18next";
import { Auth, API } from 'aws-amplify';
import { useSelector, useDispatch } from 'react-redux';
import { addTeam } from '../../store/reducers/user';
import moment from 'moment';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import CButton from "../../components/Button";
import CModal from "../../components/Modal";
import { CText } from "../../components/Text";
import CSelect from "../../components/Select";
import TextInput from '../../components/TextInput';
import { getSession } from "../../utils/auth";
import { BadRequestSnackBar } from "../../components/SnackBar";
import NoTeamsCard from "../../components/Cards/NoTeamsCard";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import championshipCategories from '../../assets/referentials/championship_categories.json';
import { ReactComponent as DotsVerticalIcon } from '../../assets/icons/dots-vertical.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as CupIcon } from '../../assets/icons/cup.svg';
import { ReactComponent as ChampionshipIcon } from '../../assets/icons/championship.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/cross.svg';
import { useStylesTeam, useStylesTeams, useStylesModal } from './teams.styles.js';
import { getCurrentSeason } from "../../utils/season";
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import Tooltip from '@mui/material/Tooltip';

const COLOR_PRIMARY = '#45d69f';
const COLOR_LIGHT = "#f4f4f4";
const COLOR_PINK= "#e92163";
const COLOR_BLUE = "#25a5e6";
const COLOR_DARK = '#242834';
const ZONE_BACKGROUND_COLOR = "#1f2126";
const BUTTON_WIDTH = 100;

const CompetitionsModal = (props) => {
  const classes = useStylesModal();

  const [competitions, setCompetitions] = useState(props.competitions);
  /********* Delete *********/
  const onClickDelete = async (competitionTeamId) => {
    const updateData = {
      id: competitionTeamId,
      deleted: true,
      deletion_date: moment().format("YYYY-MM-DD")
    };

    API.graphql({
      query: mutations.updateCompetitionTeam,
      variables: {input: updateData},
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })

    const filteredCompetitions = competitions?.filter(item => item.id !== competitionTeamId);
    setCompetitions(filteredCompetitions);
    props.competitionTeamCallback(filteredCompetitions);
  };

  return (
    <div className={classes.modalContainer}>
      <Grid container spacing={1} alignItems="flex-start">
        {competitions?.map((competition, index) => (
          <Grid container item xs={12} key={`competition-${index}`}>
            <div className={classes.info}>
              <CText className={classes.subTitle} level="p2" fontWeight="700" color="light">
               {competition.competition.name}
                <span className={classes.crossBtnZone}>
                  <span className={classes.crossBtn} onClick={() => onClickDelete(competition.id)}>
                    <CrossIcon />
                  </span>
                </span>
              </CText>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

const Team = ({ data, index, deleteCallback, errorCallback }) => {
  const { t } = useTranslation();
  const classes = useStylesTeam();

  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElType, setAnchorElType] = useState(null)
  const [deletedCompetitionIndex, setDeletedCompetitionIndex] = useState(null)
  const [competitionTeamId, setCompetitionTeamId] = useState(null)  
  const [loaded, setLoaded] = useState(false)

  //Menu
  const open = Boolean(anchorEl);
  const handleClick = (event, type, competitionIndex, competitionTeamId) => {
    setAnchorEl(event.currentTarget);
    setAnchorElType(type)

    if(type === "competition") {
      setDeletedCompetitionIndex(competitionIndex)
      setCompetitionTeamId(competitionTeamId)
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElType(null);
    setDeletedCompetitionIndex(null)
    setCompetitionTeamId(null)
  };

  const handleDelete = async() => {
    let updateData = {
      deleted: true,
      deletion_date: moment().format("YYYY-MM-DD")
    }

    if(anchorElType === "team") {
      updateData.id = data.id;
  
      await API.graphql({
        query: mutations.updateTeam,
        variables: {input: updateData},
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })
      deleteCallback(index);
    } else if (anchorElType === "competition") {
      updateData.id = competitionTeamId

      await API.graphql({
        query: mutations.updateCompetitionTeam,
        variables: {input: updateData},
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })

      const competitions = data.competitions.items.filter((item, index) => index !== deletedCompetitionIndex)
      data.competitions.items = competitions
    }

    handleClose();
  };

  // Coach
  const coachCB = (coachData) => {
    data.coach = coachData;
  }

  // Competition
  const competitionCB = (competitionData, competitionTeamData) => {
    let competitions = [...data.competitions.items];
    competitions.push({ competition: competitionData, id: competitionTeamData.id });
  
    competitions.sort((a, b) => {
      const numberA = parseInt(a.competition.name.replace(/\D/g, ''), 10) || 0;
      const numberB = parseInt(b.competition.name.replace(/\D/g, ''), 10) || 0;
      return numberA - numberB;
    });
  
    data.competitions.items = competitions;
  }

  // Team Competition
  const competitionTeamUpdate = (competitions) => {
    data.competitions.items = competitions
  }

  // Error
  const errorCB = (errorElmts) => {
    errorCallback(errorElmts)
  }

  useEffect(() => {
    const coaches = data?.users?.items
    if(coaches) {
      for(let i = 0; i < coaches.length; i++) {
        if(coaches[i].invite_status === 1) {
          data.coach = {
            id: coaches[i].user.id,
            firstName: coaches[i].user.first_name,
            lastName: coaches[i].user.last_name,
            inviteStatus: 1
          }
        } else if(coaches[i].invite_status === 0) {
          data.coach = {
            id: coaches[i].user.id,
            firstName: coaches[i].user.first_name,
            lastName: coaches[i].user.last_name,
            inviteStatus: 0
          }
        } else {
          data.coach = null
        }
      }
    }

    const competitions = data?.competitions?.items
    let newCompetitions = [];
    if(competitions) {
      for(let i = 0; i < competitions.length; i++) {
        newCompetitions.push(competitions[i])
      }
    }

    newCompetitions.sort((a, b) => {
      const numberA = parseInt(a.competition.name.replace(/\D/g, ''), 10) || 0;
      const numberB = parseInt(b.competition.name.replace(/\D/g, ''), 10) || 0;
      return numberA - numberB;
    });

    data.competitions.items = newCompetitions
    setLoaded(true)
  }, [])

  //Modal
  const [modalOpen, setModalOpen] = useState({ value: false, type: null });
  const onClickModalAdd = (type) => {
    setModalOpen({ value: true, type: type });
  }
  let contentModal;

  if(modalOpen.type === 0) {
    contentModal = <AddCoachModal team={data} modalOpenCallback={(data) => setModalOpen({value: data})} coachCallback={coachCB} errorCallback={errorCB} />
  } else if(modalOpen.type === 1) {
    contentModal = <AddCompetitionModal team={data} modalOpenCallback={(data) => setModalOpen({value: data})} competitionCallback={competitionCB} errorCallback={errorCB} />
  } else if(modalOpen.type === 2) {
    contentModal = <CompetitionsModal team={data} modalOpenCallback={(data) => setModalOpen({value: data})} competitions={data?.competitions?.items} competitionTeamCallback={competitionTeamUpdate} />
  }

  let coachInfoData;
  if(!data.coach) {
    coachInfoData =
    <div className={[classes.infoZone, classes.coachInfo2].join(" ")}>
      <CText level="p2" color="light" fontWeight={700} label={`${t('teams.labels.3')} :`} className={classes.infoTitle} />
      <div>
        <CButton
          label={t('button.add')}
          type="contained"
          color={COLOR_PRIMARY}
          size={12}
          height={24}
          minWidth={70}
          onClick={() => onClickModalAdd(0)}
        />
      </div>
    </div>
  } else {
    coachInfoData =
    <div className={[classes.infoZone, classes.coachInfo].join(" ")}>
      <CText level="p2" color="light" fontWeight={700} label={`${t('teams.labels.3')} :`} className={classes.infoTitle} />
      <div className={classes.coachZone}>
        <CText level="p2" color="light" label={`${data.coach.firstName} ${data.coach.lastName}`} className={classes.coachName} />
        {data.coach.inviteStatus === 1 ?
          null : <CText level="p3" color="blue" fontWeight={700} label={`(${t('teams.labels.4')})`} />
        }
      </div>
    </div>
  }

  const competitionsToShow = data?.competitions?.items?.length > 3 ? 2 : 3;

  return (
    <li className={classes.dataZone}>
      {loaded === true ?
        <Grid container direction="column">
          <div className={classes.teamName}>
            <CText level="p1" color="light" fontWeight={700} label={`${data.name}`} />
            <div className={classes.moreBtn} onClick={(e) => handleClick(e, "team")}>
              <DotsVerticalIcon />
            </div>
          </div>
          <div className={classes.infoZone}>
            <CText level="p2" color="light" fontWeight={700} label={`${t('teams.labels.0')} :`} className={classes.infoTitle} />
            <CText level="p2" color="light" label={`${data.category}`} />
          </div>
          <div className={classes.infoZone}>
            <CText level="p2" color="light" fontWeight={700} label={`${t('teams.labels.1')} :`} className={classes.infoTitle} />
            <CText level="p2" color="light" label={`${data.gender == "M" ? t('genders.0.longLabel') : t('genders.1.longLabel')}`} />
          </div>
          <div>
            <div className={classes.infoZone}>
              <CText level="p2" color="light" fontWeight={700} label={`${t('teams.labels.5')} :`} className={classes.infoTitle} />
              <div>
                <CButton
                  label={t('button.add')}
                  type="contained"
                  color={COLOR_PRIMARY}
                  size={12}
                  height={24}
                  minWidth={70}
                  onClick={() => onClickModalAdd(1)}
                />
              </div>
            </div>
            <div className={classes.infoList}>
              {data?.competitions?.items?.slice(0, competitionsToShow).map((competition, index) => (
                <div className={[classes.infoListElmt, classes.infoListElmtMargin].join(" ")} key={index}>
                  {competition?.competition?.type === "CHP" ?
                    <ChampionshipIcon className={classes.infoIcon} /> : <CupIcon className={classes.infoIcon} />
                  }
                  <CText level="p2" color="light">{competition?.competition?.name}</CText>
                  <div className={classes.moreBtnChampionship} onClick={(e) => handleClick(e, "competition", index, competition?.id)}>
                    <DotsVerticalIcon />
                  </div>
                </div>
              ))}
              {data?.competitions?.items?.length > 3 &&
                <button
                  className={classes.labelBtn}
                  onClick={() => onClickModalAdd(2)}
                >
                  <CText
                    level="p3"
                    fontWeight="700"
                    color="primary"
                    className={classes.labelBtnLabel}
                  >
                    {t('teams.buttons.0.label')}
                  </CText>
                </button>
              }
            </div>
          </div>
          {coachInfoData}

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            sx={{
              '& .MuiPaper-root': {
                minWidth: '10ch',
              },
            }}
            autoFocus={false}
          >
            <MenuItem onClick={handleDelete}>
              <TrashIcon className={classes.listIcon} />
              {t('teams.labels.2')}
            </MenuItem>
          </Menu>
        </Grid>
        : null
      }
      <CModal
        open={modalOpen.value}
        onClose={() => setModalOpen({ value: false })}
        content={contentModal}
      >
      </CModal>
    </li>
  );
}

const AddTeamModal = (props) => {
  const { t } = useTranslation();
  const classes = useStylesModal();
  const dispatch = useDispatch();
  const userStored = useSelector((state) => state?.user);

  const [name, setName] = useState("");
  const [category, setCategory] = useState({ code: "", label: ""});
  const [gender, setGender] = useState({ code: "", label: ""});
  const [genderLabel, setGenderLabel] = useState("");

  const genders = [
    { "label": t('genders.0.longLabel'), "code": "M" },
    { "label": t('genders.1.longLabel'), "code": "F" }
  ];

  /********* Handle Name *********/
  const nameCb = (data) => { setName(data); }

  /********* Handle Category *********/
  const categoryCb = (data) => {
    setCategory(data);
    const shortLabel = gender?.label ? `(${gender.code == "M" ? t('genders.0.shortLabel') : t('genders.1.shortLabel')})` : '';
    setName(data.label ? `${data.label} ${shortLabel}` : `${shortLabel}`);
  }
  /********* Handle Gender *********/
  const genderCb = (data) => {
    const shortLabel = data.code == "M" ? t('genders.0.shortLabel') : t('genders.1.shortLabel')
    setGender(data)
    setGenderLabel(`(${shortLabel})`)
    setName(category.label ? `${category.label} (${shortLabel})` : `(${shortLabel})`)
  }

  /********* Create Team *********/
  const createTeam = async () => {
    try {
      if (category && gender) {
        const clubId = userStored.club.id;
        const teams = [...props.teams];
  
        const data = {
          "name": name,
          "category": category.code,
          "gender": gender.code,
          "club_id": clubId
        };
        await getSession();
        const resp = await API.graphql({ query: mutations.createTeam, variables: { input: data }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
        const newTeam = {
          category: resp.data.createTeam.category,
          gender: resp.data.createTeam.gender == "M" ? t('genders.0.shortLabel') : t('genders.1.shortLabel'),
          id: resp.data.createTeam.id,
          name: resp.data.createTeam.name,
          competitions: []
        };

        const teamIdStored = userStored?.club?.team?.id;

        if ((teamIdStored === undefined || !teamIdStored)) {
          dispatch(addTeam({
            clubId: clubId,
            teamId: resp.data.createTeam.id
          }));
        }
  
        const newTeams = [...teams];
        let added = false;
        let indexToAdd = 0;
  
        if (newTeams.length > 0) {
          for (let i = 0; i < newTeams.length; i++) {
            const numberNewTeam = parseInt(newTeam.name.replace(/\D/g, ''), 10) || 0;
            const numberExistingTeam = parseInt(newTeams[i].name.replace(/\D/g, ''), 10) || 0;
        
            if (!added && numberNewTeam < numberExistingTeam) {
              indexToAdd = i;
              added = true;
              break;
            }
          }
        }

        if (added) {
          newTeams.splice(indexToAdd, 0, newTeam);
        } else {
          newTeams.push(newTeam);
        }

        await props.updateTeams(newTeams);
        props.modalOpenCallback(false);
      } else {
        let errorElmts = [];
        if (!category) {
          errorElmts.push(t('snack_bars.0.elements.0.label'));
        }
        if (!gender) {
          errorElmts.push(t('snack_bars.0.elements.1.label'));
        }
        props.errorCallback(errorElmts);
      }
    } catch (err) {
      console.log('error fetching:', err);
    }
  };
  return (
    <div className={classes.modalContainer}>
      <div className={classes.form}>
        <CText className={classes.formTitle} level="h2" fontWeight={700} color="primary" label={`${t('teams.modals.0.title')}`} />
        <div className={[classes.input, classes.inputInline].join(" ")}>
          <div className={classes.inputLeft}>
            <CSelect
              label={`${t('teams.modals.0.inputs.0.label')} *`}
              data={championshipCategories}
              selectCallback={categoryCb}
              value={category}
            />
          </div>
          <div className={classes.inputRight}>
            <CSelect
              label={`${t('teams.modals.0.inputs.1.label')} *`}
              data={genders}
              selectCallback={genderCb}
            />
          </div>
        </div>
        <TextInput
          className={classes.input}
          label={`${t('teams.modals.0.inputs.2.label')}`}
          inputCallback={nameCb}
          defaultValue={name}
        />
        <div className={classes.formBtnZone}>
          <CButton
            className={classes.formBtn}
            label={t('teams.modals.0.buttons.0.label')}
            height={38}
            type="contained"
            loader={true}
            onClick={async () => await createTeam()}
          />
        </div>
      </div>
    </div>
  );
}

const AddCoachModal = (props) => {
  const { t } = useTranslation();
  const classes = useStylesModal();

  /********* Handle Email *********/
  const [email, setEmail] = useState("");
  const emailCb = (data) => setEmail(data);

  /********* Send Invite *********/
  const sendInvite = async() => {
    let inviteUserInputs;
    let coachData;

    try {
      if(email) {
        const teamId = props.team.id
        const coachFilter = {
          email: { eq: email },
          type: { eq: 'C' }
        }

        await getSession()
        const listUsersResp = await API.graphql({ query: queries.listUsers, variables: { filter: coachFilter }, authMode: 'AMAZON_COGNITO_USER_POOLS'} )
        const coach = listUsersResp.data.listUsers.items[0];

        const currentSeason = await getCurrentSeason();

        inviteUserInputs = {
          "season": currentSeason,
          "user": {},
          "type": "C"
        }

        if(coach) {
          inviteUserInputs.user.id = coach.id;
        } else {
          inviteUserInputs.user.email = email;
        }

        let resp = await fetch(`${process.env.REACT_APP_REST_API_BASE_URL}/invitations/v1/teams/${teamId}/invitations`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
          },
          body: JSON.stringify(inviteUserInputs)
        });

        const statusCode = resp.status;

        if(coach) {
          coachData = {
            id: coach.id,
            firstName: coach.first_name,
            lastName: coach.last_name,
            inviteStatus: 0
          }
        } else {
          coachData = {
            email: email
          }
        }

        props.coachCallback(coachData)
        props.modalOpenCallback(false)
      } else {
        const errorElmts = [t('snack_bars.0.elements.2.label')]
        props.errorCallback(errorElmts)
      }

    } catch (err) { console.log('error fetching:', err) }
  }

  return (
    <div className={classes.modalContainer}>
      <div className={classes.form}>
        <CText className={classes.formTitle} level="h2" fontWeight={700} color="primary" label={`${t('teams.modals.1.title')}`} />
        <TextInput className={classes.input} label={`${t('teams.modals.1.inputs.0.label')} *`} inputCallback={emailCb} value={email} />
        <div className={classes.formBtnZone}>
          <CButton
            className={classes.formBtn}
            label={t('teams.modals.1.buttons.0.label')}
            height={38}
            type="contained"
            loader={true}
            onClick={async () => await sendInvite()}
          />
        </div>
      </div>
    </div>
  );
}

const AddCompetitionModal = (props) => {
  const { t } = useTranslation()
  const classes = useStylesModal()

  /********* Handle Name *********/
  const [name, setName] = useState("")
  const nameCb = (data) => setName(data)

  /********* Handle Category *********/
  const [category, setCategory] = useState({code: "", label: ""})
  const categoryCb = (data) => { setCategory(data) };

  /********* Handle Gender *********/
  const [gender, setGender] = useState({code: "", label: ""})
  const genderCb = (data) => setGender(data)

  /********* Handle Type *********/
  const [type, setType] = useState({code: "", label: ""})
  const typeCb = (data) => setType(data)

  /********* Send Invite *********/
  const onClickSave = async() => {
    if(category && gender && name && type) {
      try {
        const teamId = props.team.id

        // Current Season
        const searchSeasonsResp = await API.graphql({ query: queries.searchSeasons, authMode: 'AMAZON_COGNITO_USER_POOLS'})
        const currentSeason = searchSeasonsResp.data.searchSeasons.items[0].name

        // Create Competition
        const competitionInputs = {
          category: category.code,
          gender: gender.code,
          name: name,
          type: type.code
        }
        const competition = await API.graphql({ query: mutations.createCompetition, variables: { input: competitionInputs }, authMode: 'AMAZON_COGNITO_USER_POOLS'})
        
        // Create Competition Team
        const competitionTeamInputs = {
          competition_id: competition.data.createCompetition.id,
          competitionTeamsId: competition.data.createCompetition.id,
          team_id: teamId,
          teamCompetitionsId: teamId,
          season: currentSeason
        }
        let competitionTeam = await API.graphql({ query: mutations.createCompetitionTeam, variables: { input: competitionTeamInputs }, authMode: 'AMAZON_COGNITO_USER_POOLS'})
        props.competitionCallback(competition.data.createCompetition, competitionTeam.data.createCompetitionTeam)
        props.modalOpenCallback(false)

      } catch (err) { console.log('error fetching:', err) }
    } else {
      let errorElmts = []
      if(!name) {
        errorElmts.push(t('snack_bars.0.elements.3.label'))
      }
      if(!type) {
        errorElmts.push(t('snack_bars.0.elements.4.label'))
      }
      if(!category) {
        errorElmts.push(t('snack_bars.0.elements.0.label'))
      }
      if(!gender) {
        errorElmts.push(t('snack_bars.0.elements.1.label'))
      }
      props.errorCallback(errorElmts);
    }
  }

  const genders = [
    { "label": t('genders.0.longLabel'), "code": "M" },
    { "label": t('genders.1.longLabel'), "code": "F" }
  ]

  const competitionTypes = [
    { "label": t('competition_types.0.label'), "code": "CHP" },
    { "label": t('competition_types.1.label'), "code": "CUP" }
  ]

  return (
    <div className={classes.modalContainer}>
      <CText className={classes.formTitle} level="h2" fontWeight={700} color="primary" label={`${t('teams.modals.2.title')}`} />
      <div className={classes.form}>
        <TextInput className={classes.input} label={`${t('teams.modals.2.inputs.0.label')} *`} inputCallback={nameCb} value={name} />
        <div className={[classes.input, classes.inputInline].join(" ")}>
          <div className={classes.inputLeft}>
            <CSelect
              label={`${t('teams.modals.2.inputs.1.label')} *`}
              data={competitionTypes}
              selectCallback={typeCb}
              value={type}
            />
          </div>
          <div className={classes.inputRight}>
            <CSelect
              label={`${t('teams.modals.2.inputs.2.label')} *`}
              data={championshipCategories}
              selectCallback={categoryCb}
              value={category}
            />
          </div>
        </div>
        <div className={[classes.input, classes.halfInput].join(" ")}>
          <CSelect
            label={`${t('teams.modals.2.inputs.3.label')} *`}
            data={genders}
            selectCallback={genderCb}
            value={gender}
          />
        </div>
        <div className={classes.formBtnZone}>
          <CButton
            className={classes.formBtn}
            label={t('teams.modals.2.buttons.0.label')}
            height={38}
            type="contained"
            loader={true}
            onClick={async () => await onClickSave()}
          />
        </div>
      </div>
    </div>
  );
}

const Teams = (props) => {
  const { t } = useTranslation();
  const classes = useStylesTeams();
  const userStored = useSelector((state) => state?.user)

  const [modalOpen, setModalOpen] = useState(false);
  const [teams, setTeams] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [teamQuotaReached, setTeamQuotaReached] = useState(false);
  const [appSubscription, setAppSubscription] = useState({});

  const getAppSubscription = async () => {
    const today = new Date().toISOString().split('T')[0];
    const filter = {
      club_id: { eq: userStored?.club?.id },
      paid: {eq: true},
      or: [
        { end_date: { attributeExists: false } },
        { end_date: { ge: today } }
      ]
    }

    let appSubscriptions = await API.graphql({ query: queries.listAppSubscriptions, variables: { filter: filter }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
    appSubscriptions = appSubscriptions.data.listAppSubscriptions.items;
    const appSubscription = appSubscriptions[0].plan;
    setAppSubscription(appSubscription);

    return appSubscription;
  }

  const getTeams = async() => {
    try {
      const clubId = userStored.club.id;
      const currentSeason = await getCurrentSeason();

      const filter = {
        club_id: { eq: clubId },
        deleted: { eq: false }
      };

      const competitionFilter = {
        season: { eq: currentSeason },
        deleted: { eq: false }
      };

      const userFilter = {
        or: [
          { invite_status: { eq: 0 }},
          { invite_status: { eq: 1 }}
        ],
        user_type: { eq: "C" },
        end_date: { attributeExists: false },
        season: { eq: currentSeason }
      }

      let teamsList = await API.graphql({ query: queries.listTeams, variables: { filter: filter, competitionFilter: competitionFilter, userFilter: userFilter }, authMode: 'AMAZON_COGNITO_USER_POOLS' });
      teamsList = teamsList.data.listTeams.items;

      const teamsListOrdered = teamsList.sort((a, b) => {
        const numberA = parseInt(a.name.replace(/\D/g, ''), 10) || 0;
        const numberB = parseInt(b.name.replace(/\D/g, ''), 10) || 0;
        return numberA - numberB;
      });

      setTeams(teamsListOrdered);

      return teamsListOrdered;
    }
    catch(err) { console.log(err) }
  }

  useEffect(() => {
    const fetchData = async () => {
      const teams = await getTeams();
      const appSubscription = await getAppSubscription();

      if(teams.length >= appSubscription.max_teams) {
        setTeamQuotaReached(true);
      }

      setLoaded(true);
    }
    
    fetchData();
  }, []);
  
  const onClickModalAdd = () => {
    setModalOpen(true);
  }

  const deleteCB = (index) => {
    const temp = [...teams]
    temp.splice(index, 1)
    setTeams(temp)
  }

  /********* Handle Error *********/
  const [openSnack, setOpenSnack] = useState(false)
  const [snackBarErrorElmts, setSnackBarErrorElmts] = useState("")

  const errorCB = (errorElmts) => {
    setOpenSnack(true)
    setSnackBarErrorElmts(errorElmts)
  }

  const closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  }

  const updateTeams = async(newTeams) => {
    setTeams(newTeams);

    if(newTeams.length >= appSubscription.max_teams) {
      setTeamQuotaReached(true);
    }
  };

  // Content Modal
  let contentModal

  if(loaded) {
    contentModal = <AddTeamModal
      teams={teams}
      updateTeams={updateTeams}
      modalOpenCallback={(data) => setModalOpen(data)}
      errorCallback={errorCB}
    />
  }

  return(
    <div>
    {loaded ?
      <div className={classes.container}>
        <CText level="h1" color="light">{`${t('teams.title')}`}</CText>
        <div className={classes.topButtonsContainer}>
          <div className={classes.topButtonsContainerRight}>
            {teamQuotaReached ?
              <Tooltip title={<span>{t('teams.tooltips.0.label')} <strong>{appSubscription.name}</strong></span>}>
                <InfoIcon className={classes.info} />
              </Tooltip>
              :
              null
            }
            <CButton
              className={classes.addButton}
              label={t('button.add')}
              type="contained"
              color={COLOR_PRIMARY}
              size={14}
              minWidth={BUTTON_WIDTH}
              maxWidth={BUTTON_WIDTH}
              disabled={teamQuotaReached}
              onClick={async () => await onClickModalAdd()}
            />
          </div>
        </div>

        {loaded === true && teams.length < 1 ?
          <div className={classes.noTeamsInfo}>
            <NoTeamsCard redirectBtn={false} />
          </div>
          :
          <div className={classes.list}>
            <Fragment>
              <Grid container spacing={1}>
                {teams?.map((team, idx) => (
                  <Grid container item xs={12} sm={6} md={6} lg={4} xl={4} key={idx} justifyContent="center" alignItems="stretch">
                    <Team data={team} index={idx} deleteCallback={deleteCB} errorCallback={errorCB}/>
                  </Grid>
                ))}
              </Grid>
            </Fragment>
          </div>
        }

        <BadRequestSnackBar
          open={openSnack}
          onClose={closeSnackBar}
          autoHideDuration={5000}
          errorElmts={snackBarErrorElmts}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        />

        <CModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          content={contentModal}
        >
        </CModal>
      </div>
      :null
    }
    </div>
  )
}

export default Teams;