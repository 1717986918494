import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CText } from "../Text";
import CButton from "../Button";
import { ReactComponent as DocumentIcon } from '../../assets/icons/documents.svg';
import { getSession } from "../../utils/auth";
import './card.css';

const NoDocumentsCard = ({redirectBtn, className, teamId}) => {  
  const { t } = useTranslation();

  return (
    <div className={`cardZone ${className}`}>
      <div className="titleZone">
        <div className="titleIconZone">
          <DocumentIcon className="titleIcon" />
        </div>
        <CText level="h3" color="primary">
          {`${t('no_documents_card.title')}`}
        </CText>
      </div>
    </div>
  );
}

export default NoDocumentsCard;