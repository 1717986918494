import React, { useState, useEffect } from 'react';
import SwipeableDrawer from '../Drawer/SwipeableDrawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { CText } from "../../components/Text";
import { menuList } from "./list";
import { API } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import './swipeable-menu.css';
import { ClubPhoto } from "../../components/Photos/ClubPhoto";
import { ReactComponent as RightChevronIcon } from '../../assets/icons/right-chevron.svg';
import CButton from "../../components/Button";

const COLOR_PRIMARY = '#45d69f';

const SwipeableMenu = ({ anchor = "right", open, toggleDrawer }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user = useSelector(state => state?.user);
  const userId = user?.info?.id;
  const teamId = user?.club?.team?.id;

  const documentsRequestedCount = useSelector((state) => state?.documents?.requested_count);
  const invitationCount = useSelector((state) => state?.invitations?.count);
  const firstConversation = useSelector((state) => state?.conversations?.conversations?.[0]);
  const conversationCount = firstConversation ? firstConversation.count : 0;  

  const [menu, setMenu] = useState([]);
  const [loading, setLoading] = useState(true);
  const [team, setTeam] = useState({});

  useEffect(() => {
    const fetchMenu = async () => {
      const fetchedMenu = await menuList(t, user);
      const teamMenuItem = fetchedMenu?.find(item => item.id === "team");
      const otherMenuItems = fetchedMenu?.filter(item => item.id !== "team");
      const orderedMenu = teamMenuItem ? [...otherMenuItems, teamMenuItem] : otherMenuItems;
      setMenu(orderedMenu);
      setLoading(false);
    };
    fetchMenu();
  }, [t, user]);

  useEffect(() => {
    if (teamId) {
      getTeam(teamId);
    }
  }, [teamId]);

  const getTeam = async (teamId) => {
    try {
      const getTeamResp = await API.graphql({
        query: queries.getTeam,
        variables: { id: teamId },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      setTeam(getTeamResp.data.getTeam);
    } catch (error) {
      console.error("Error fetching team:", error);
    }
  };

  const changeTeam = () => {
    const queryParams = location.search;
    if (user?.groups?.includes("ClubAdmins")) {
      navigate(`/clubs/${user.club.id}/teams${queryParams}`, { state: { prevUrl: window.location.pathname + queryParams } });
    } else if (user?.groups?.includes("Players") || user?.groups?.includes("Coaches")) {
      navigate(`/users/${user.info.id}/teams${queryParams}`, { state: { prevUrl: window.location.pathname + queryParams } });
    }
  };

  const handleNavigate = (path, item) => {
    const teamName = user?.club?.team?.name || 'no_team';
    const currentPath = path.replace(':teamName', encodeURIComponent(teamName));
    navigate(currentPath);
    toggleDrawer(false)();
    window.scrollTo(0, 0);
  };

  const isPathActive = (currentPath, menuPath) => {
    const segmentsMenu = menuPath?.split('/');
    const segmentsCurrent = currentPath?.split('/');
    if (segmentsMenu?.length !== segmentsCurrent?.length) return false;
    for (let i = 0; i < segmentsMenu?.length; i++) {
      if (segmentsMenu[i] !== ':teamName' && segmentsMenu[i] !== segmentsCurrent[i]) return false;
    }
    return true;
  };

  const renderSubMenu = (submenu) => (
    submenu.map((subItem) => {
      const isActiveSub = isPathActive(location.pathname, subItem.link_to);
      return (
        <div
          onClick={() => handleNavigate(subItem.link_to, subItem)}
          key={subItem.title}
          className={`swipeable-menu-title-zone swipeable-menu-subtitle-zone ${isActiveSub ? "swipeable-menu-title-zone-focus" : ""}`}
        >
          <div className={isActiveSub ? "swipeable-menu-icon-focus" : "swipeable-menu-icon"}>
            {subItem.icon}
          </div>
          <CText fontSize={15} fontWeight={500} label={subItem.title} />
        </div>
      );
    })
  );

  const renderMenu = (menuItem) => {
    const isActiveMenu = isPathActive(location.pathname, menuItem.link_to);

    if (menuItem.id === "team" && team.name) {
      return (
        <div key={menuItem.id}>
          <Tooltip
            disableInteractive
            title={`${team?.club?.names?.[0] ? JSON.parse(team.club.names[0]).name : ''} • ${team?.name || ''}`}
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -20],
                  },
                },
                {
                  name: 'zIndex',
                  enabled: true,
                  phase: 'afterWrite',
                  fn: ({ state }) => {
                    state.styles.popper.zIndex = '100000';
                  },
                },
              ],
            }}
          >
            <span className="swipeable-menu-team" onClick={changeTeam}>
              <div className="swipeable-menu-team-logo-zone">
                <ClubPhoto className="swipeable-menu-team-logo" src={team?.club?.logo} />
              </div>
              <CText
                className="label"
                level="p2"
                fontWeight={700}
                color="light"
              >
                {team?.name?.length > 7 ? `${team.name.slice(0, 5)}...` : team.name}
              </CText>
              <RightChevronIcon
                className="swipeable-menu-chevron-icon"
              />
            </span>
          </Tooltip>
          <div>
            {renderSubMenu(menuItem.submenu)}
          </div>
        </div>
      );
    } else if(menuItem.id === "team" && !team?.name) {
      return (
        <div key={menuItem.id} className="swipeable-menu-btn-wrapper">
          <CButton
            className="swipeable-menu-btn"
            label={t('menu.1.btns.0.label')}
            type="contained"
            color={COLOR_PRIMARY}
            size={14}
            height={32}
            onClick={changeTeam}
          />
        </div>
      );
    }

    return menuItem?.submenu?.length < 1 ? (
      <div
        onClick={() => handleNavigate(menuItem.link_to, menuItem)}
        className={`swipeable-menu-title-zone ${isActiveMenu ? "swipeable-menu-title-zone-focus" : ""}`}
      >
        <div className={isActiveMenu ? "swipeable-menu-icon-focus" : "swipeable-menu-icon"}>
          {menuItem.icon}
        </div>
        <CText fontSize={15} fontWeight={500}>
          {menuItem.title}

          {menuItem.id === "invitations" && invitationCount > 0 && (
            <span className="swipeable-menu-badge-zone">
              <span className="swipeable-menu-badge">
                {invitationCount > 9 ? (
                  <span className="swipeable-menu-badge-content">
                    <span>9</span>
                    <span className="swipeable-menu-badge-plus">+</span>
                  </span>
                ) : (
                  <span>{invitationCount}</span>
                )}
              </span>
            </span>
          )}

          {menuItem.id === "documents" && documentsRequestedCount > 0 && (
            <span className="swipeable-menu-badge-zone">
              <span className="swipeable-menu-badge">
                {documentsRequestedCount > 9 ? (
                  <span className="swipeable-menu-badge-content">
                    <span>9</span>
                    <span className="swipeable-menu-badge-plus">+</span>
                  </span>
                ) : (
                  <span>{documentsRequestedCount}</span>
                )}
              </span>
            </span>
          )}

          {menuItem.id === "messages" && conversationCount > 0 && (
            <span className="swipeable-menu-badge-zone">
              <span className="swipeable-menu-badge">
                {conversationCount > 9 ? (
                  <span className="swipeable-menu-badge-content">
                    <span>9</span>
                    <span className="swipeable-menu-badge-plus">+</span>
                  </span>
                ) : (
                  <span>{conversationCount}</span>
                )}
              </span>
            </span>
          )}
        </CText>
      </div>
    ) : null;
  };

  if (loading) return null;

  return (
    <SwipeableDrawer anchor={anchor} open={open} toggleDrawer={toggleDrawer}>
      <Box role="presentation">
        <List>
          {menu?.map((menuItem, index) => (
            <React.Fragment key={index}>
              {menuItem?.id === "team" && index > 0 && <Divider className="swipeable-menu-divider" />}
              {renderMenu(menuItem)}
            </React.Fragment>
          ))}
        </List>
      </Box>
    </SwipeableDrawer>
  );
};

export default SwipeableMenu;