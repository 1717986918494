import React, { useState } from 'react'
import { styled } from '@material-ui/core/styles';
import Modal from '@mui/material/Modal';
import {makeStyles} from "@material-ui/core/styles";

const COLOR_PRIMARY = '#101116';
const TEXTFIELD_COLOR = '#242834';
const iconSize = 28;

const CModal = ({open, onClose, title, content, contentPosition, width, height, className }) =>{ 
  const useStyles = makeStyles((theme) => ({
    content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#1A1919',
      borderRadius: 18,
      outline: 'none',
      overflow:'scroll',
      [theme.breakpoints.up('xs')]: {
        width: 'calc(100% - 2*3%)'
      },
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        maxWidth: 500
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: 500
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 550
      }
    }
  }));
  const classes = useStyles();

  return(
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      className={classes.container}
    >
      <div className={[classes.content, className].join(" ")}>
        {content}
      </div>
    </Modal>
  );
}

export default CModal;