import { t } from 'i18next';

export const getPositionLabel = (positionCode) => {
  const positionMap = {
    GK: t('positions.0.shortLabel'),
    DF: t('positions.1.shortLabel'),
    MF: t('positions.2.shortLabel'),
    ST: t('positions.3.shortLabel'),
  };

  return positionMap[positionCode] || '';
};


export const sortByPosition = (bench) => {
  const positionPriority = {
    GK: 1,
    DF: 2,
    MF: 3,
    ST: 4
  };

  return bench.sort((a, b) => {
    const posA = positionPriority[a.player?.position] || 5;
    const posB = positionPriority[b.player?.position] || 5;
    return posA - posB;
  });
};