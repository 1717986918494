import React from 'react';
import ProfilePicture from "../../components/ProfilePicture/index";
import { CText } from "../../components/Text";
import { getPositionLabel } from '../../utils/position';
import './row-player.styles.css';
import { ReactComponent as Cross } from '../../assets/icons/cross.svg';

export const RowPlayer = ({ player, onClick, editable }) => {
  let position = getPositionLabel(player.position);

  return (
    <div className="row-player">
      <div className="row-player-photo-container">
        <ProfilePicture className="row-player-photo" src={player?.photo_path} />
      </div>

      <CText className="row-player-name" level="p2" color="light">
        {player?.first_name} {player?.last_name}
      </CText>

      <div className="row-player-right">
        <div className={`row-player-position`}>
          {position}
        </div>

        {editable ?
          <div
            className="row-player-remove-btn"
            onClick={() => onClick()}
          >
            <Cross />
          </div>
          : null
        }
      </div>
    </div>
  );
};