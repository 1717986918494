import React, { useState, useEffect } from 'react';
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useTranslation } from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as CardIcon } from '../../assets/icons/card.svg';
import { ReactComponent as PitchIcon } from '../../assets/icons/pitch.svg';
import { ReactComponent as BallIcon } from '../../assets/icons/ball.svg';
import { ReactComponent as AssistIcon } from '../../assets/icons/assist.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/cross.svg';
import { ReactComponent as MedicalKitIcon } from '../../assets/icons/medical-kit.svg';
import { ReactComponent as DotsIcon } from '../../assets/icons/dots-vertical.svg';
import ProfilePicture from "../ProfilePicture/index";
import { useNavigate } from "react-router-dom";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { getSession } from "../../utils/auth";
import { Auth } from 'aws-amplify';

const COLOR_PRIMARY = '#45d69f'
const COLOR_LIGHT = "#f4f4f4"
const COLOR_PINK= "#e92163"
const COLOR_BLUE = "#25a5e6"
const COLOR_DARK = '#242834'
const COLOR_RED = "#fc3c2e"
const COLOR_YELLOW = "#EDA247"
const ZONE_BACKGROUND_COLOR = "#1f2126"
const BUTTON_WIDTH = 100

const useStyles = makeStyles((theme) => ({
  cardIcon: {
    width: 18,
    height: 18
  },
  icon: {
    width: 20,
    height: 20,
    fill: COLOR_LIGHT
  },
  iconBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    height: 20,
    fill: COLOR_LIGHT,
    cursor: 'pointer',
    borderRadius: 25,
    marginRight: 7,
    padding: 3,
    "&:hover": {
      backgroundColor: '#232e2f',
      filter: 'brightness(90%)',
      boxShadow: 'none'
    }
  },
  yellowCard: {
    fill: COLOR_YELLOW
  },
  redCard: {
    fill: COLOR_RED
  },
  playerPhoto: {
    width: 35,
    height: 35,
    borderRadius: '50%',
    border: '1px solid',
    marginRight: 10
  },
  listRemove: {
    backgroundColor: `${COLOR_PINK} !important`
  },
  listIcon: {
    width: 17,
    height: 17,
    fill: COLOR_DARK,
    stroke: COLOR_DARK
  },
  listIconZone: {
    marginRight: 8,
    display: 'flex',
    justifyContent: 'center'
  }
}))

const createData = (id, player, photo_path, position, game, goal, assist, yellow_card, red_card, invitation_id) => {
  return {
    id,
    player,
    photo_path,
    position,
    game,
    goal,
    assist,
    yellow_card,
    red_card,
    invitation_id
  }
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

const EnhancedTableHead = (props) => {
  const classes = useStyles();
  const headCells = [
    {
      id: "player",
      align: 'left',
      numeric: false,
      disablePadding: false,
      label: props.translation('players.labels.0'),
      icon: null
    },
    {
      id: "position",
      align: 'center',
      numeric: false,
      disablePadding: true,
      label: props.translation('players.labels.1'),
      icon: null
    },
    {
      id: "game",
      align: 'center',
      numeric: true,
      disablePadding: false,
      label: props.translation('players.labels.2'),
      icon: <PitchIcon className={classes.icon} />
    },
    {
      id: "goal",
      align: 'center',
      numeric: true,
      disablePadding: true,
      label: props.translation('players.labels.3'),
      icon: <BallIcon className={classes.icon} />
    },
    {
      id: "assist",
      align: 'center',
      numeric: true,
      disablePadding: true,
      label: props.translation('players.labels.4'),
      icon: <AssistIcon className={classes.icon} />
    },
    {
      id: "yellow_card",
      align: 'center',
      numeric: true,
      disablePadding: true,
      label: props.translation('players.labels.5'),
      icon: <CardIcon className={[classes.cardIcon, classes.yellowCard].join(" ")} />,
    },
    {
      id: "red_card",
      align: 'center',
      numeric: true,
      disablePadding: true,
      label: props.translation('players.labels.6'),
      icon: <CardIcon className={[classes.cardIcon, classes.redCard].join(" ")} />,
    }
  ]

  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            style={{
              color: COLOR_LIGHT,
              borderBottom: 'none'
            }}
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              sx={{
                fontSize: 14,
                fontWeight: 700,
                cursor: 'pointer',
                '&.Mui-selected': {
                  '& > *': {
                    color: COLOR_LIGHT,
                  },
                },
                '&.Mui-active': {
                  color: COLOR_LIGHT,
                  '& .MuiSvgIcon-root:not(.Mui-active)': {
                    fill: COLOR_LIGHT
                  }
                },
                '&:hover': {
                  color: COLOR_LIGHT,
                  filter: 'brightness(90%)',
                  '& > *': {
                    filter: 'brightness(90%)'
                  }
                }
              }}
            >
              {headCell.icon ?
                <Tooltip title={`${headCell.label}`}>
                  {headCell.icon}
                </Tooltip>
                : headCell.label
              }
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const PlayersTable = ({ data, type }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  let navigate = useNavigate()

  const [order, setOrder] = React.useState("asc")
  const [orderBy, setOrderBy] = React.useState("")
  const [selected, setSelected] = React.useState([])
  const [rows, setRows] = useState([]);
  const [userGroups, setUserGroups] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (data) {
        const session = await getSession();
        const groups = session.idToken.payload['cognito:groups'];
        setUserGroups(groups)
  
        const newRows = data.map((item) => {
          const fullName = `${item.first_name} ${item.last_name}`;
          return createData(item.id, fullName, item.photo_path, item.position, item.matches, item.goals, item.assists, item.yellow_cards, item.red_cards, item.invitation_id);
        });
        setRows(newRows);
      }
    }
  
    fetchData()
  }, [data]);

  const visibleRows = React.useMemo(() => {
    return stableSort(rows, getComparator(order, orderBy));
  }, [rows, order, orderBy]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = rows.map(n => n.player)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const navigateToHealthForm = (event, player) => {
    const url = window.location.pathname;
    const parts = url.split('/');
    const teamId = parts[parts.length - 2];
    navigate(`/teams/${teamId}/players/${player.id}`, { replace: true });
    window.scrollTo(0, 0);
  }

  const removePlayer = async(invitationId) => {
    if(userGroups?.includes("Coaches") || userGroups?.includes("Players")) {
      setRows(prevRows => prevRows.filter(row => row.invitation_id !== invitationId));
    
      await fetch(`${process.env.REACT_APP_REST_API_BASE_URL}/invitations/v1/invitations/${invitationId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
      });
    }
  }

  //Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickMore = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableContainer>
    <Table
      sx={{}}
      aria-labelledby="tableTitle"
    >
      <EnhancedTableHead
        numSelected={selected.length}
        order={order}
        orderBy={orderBy}
        onSelectAllClick={handleSelectAllClick}
        onRequestSort={handleRequestSort}
        rowCount={rows.length}
        translation={t}
      />
      <TableBody>
        {visibleRows.map((row, index) => {
          const labelId = `enhanced-table-checkbox-${index}`
          const rowColor = index % 2 === 0 ? '#24262a' : '#1d2027';

          return (
            <TableRow
              tabIndex={-1}
              key={row.id}
              sx={{
                backgroundColor: rowColor,
                '&:hover': {
                  filter: 'brightness(90%)'
                },
                borderBottom: 'none',
                color: COLOR_LIGHT
              }}
            >
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                style={{ borderBottom: 'none', color: COLOR_LIGHT, display: 'flex', flexDirection: 'row', alignItems: 'center' }}
              >
                {type === "Coaches" || type === "ClubAdmins" ?
                  <div>
                    <Tooltip title={t('players.labels.8')}>
                      <DotsIcon
                        className={`${classes.iconBtn} `}
                        onClick={handleClickMore}
                      />
                    </Tooltip>
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      sx={{
                        '& .MuiPaper-root': {
                          minWidth: '10ch',
                        },
                      }}
                      autoFocus={false}
                    >
                      <MenuItem
                        onClick={event => navigateToHealthForm(event, row)}
                      >
                        <span className={classes.listIconZone}>
                          <MedicalKitIcon className={classes.listIcon} />
                        </span>
                        {t('players.labels.9')}
                      </MenuItem>
                      <MenuItem
                        className={classes.listRemove}
                        onClick={event => removePlayer(row.invitation_id)}
                      >
                        <span className={classes.listIconZone}>
                          <CrossIcon className={classes.listIcon} />
                        </span>
                        {t('players.labels.10')}
                      </MenuItem>
                    </Menu>
                  </div>
                  : null
                }
                <div>
                  <ProfilePicture className={classes.playerPhoto} src={row.photo_path} />
                </div>
                {row.player}
              </TableCell>
              <TableCell align="center" style={{ borderBottom: 'none', color: COLOR_LIGHT, paddingRight: '42px'}}>{row.position}</TableCell>
              <TableCell align="center" style={{ borderBottom: 'none', color: COLOR_LIGHT, paddingRight: '42px'}}>{row.game}</TableCell>
              <TableCell align="center" style={{ borderBottom: 'none', color: COLOR_LIGHT, paddingRight: '42px'}}>{row.goal}</TableCell>
              <TableCell align="center" style={{ borderBottom: 'none', color: COLOR_LIGHT, paddingRight: '42px'}}>{row.assist}</TableCell>
              <TableCell align="center" style={{ borderBottom: 'none', color: COLOR_LIGHT, paddingRight: '42px'}}>{row.yellow_card}</TableCell>
              <TableCell align="center" style={{ borderBottom: 'none', color: COLOR_LIGHT, paddingRight: '42px'}}>{row.red_card}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  </TableContainer>
  )
}

export default PlayersTable;